define("discourse/plugins/chat/discourse/lib/chat-message-flag", ["exports", "@ember/application", "@ember/service", "discourse/lib/ajax-error", "discourse-common/lib/get-url", "discourse-i18n"], function (_exports, _application, _service, _ajaxError, _getUrl, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatMessageFlag = _exports.default = (_class = class ChatMessageFlag {
    constructor(owner) {
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      (0, _application.setOwner)(this, owner);
    }
    title() {
      return "flagging.title";
    }
    customSubmitLabel() {
      return "flagging.notify_action";
    }
    submitLabel() {
      return "chat.flagging.action";
    }
    targetsTopic() {
      return false;
    }
    editable() {
      return false;
    }
    _rewriteFlagDescriptions(flags) {
      return flags.map(flag => {
        flag.set("description", _discourseI18n.default.t(`chat.flags.${flag.name_key}`, {
          basePath: (0, _getUrl.default)(""),
          defaultValue: ""
        }));
        return flag;
      });
    }
    flagsAvailable(flagModal) {
      let flagsAvailable = flagModal.site.flagTypes;
      flagsAvailable = flagsAvailable.filter(flag => {
        return flagModal.args.model.flagModel.availableFlags.includes(flag.name_key);
      });

      // "message user" option should be at the top
      const notifyUserIndex = flagsAvailable.indexOf(flagsAvailable.filterBy("name_key", "notify_user")[0]);
      if (notifyUserIndex !== -1) {
        const notifyUser = flagsAvailable[notifyUserIndex];
        flagsAvailable.splice(notifyUserIndex, 1);
        flagsAvailable.splice(0, 0, notifyUser);
      }
      return this._rewriteFlagDescriptions(flagsAvailable);
    }
    async create(flagModal, opts) {
      flagModal.args.closeModal();
      const channelId = flagModal.args.model.flagModel.channel.id;
      const messageId = flagModal.args.model.flagModel.id;
      try {
        await this.chatApi.flagMessage(channelId, messageId, {
          flag_type_id: flagModal.selected.id,
          message: opts.message,
          is_warning: opts.isWarning,
          take_action: opts.takeAction,
          queue_for_review: opts.queue_for_review
        });
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});