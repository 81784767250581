define("discourse/plugins/chat/discourse/components/chat/routes/channel-thread", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "discourse/plugins/chat/discourse/components/chat/thread/header", "discourse/plugins/chat/discourse/components/chat-thread", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _header, _chatThread, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatRoutesChannelThread;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatRoutesChannelThread = _exports.default = (_class = (_ChatRoutesChannelThread = class ChatRoutesChannelThread extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "showfullTitle", _descriptor, this);
    }
    setFullTitle(value1) {
      this.showfullTitle = value1;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-routes-channel-thread">
        {{#each (array @thread) as |thread|}}
          <ThreadHeader
            @thread={{thread}}
            @showFullTitle={{this.showfullTitle}}
          />
  
          <Thread
            @thread={{thread}}
            @targetMessageId={{@targetMessageId}}
            @includeHeader={{true}}
            @setFullTitle={{this.setFullTitle}}
          />
        {{/each}}
      </div>
    
  */
  {
    "id": "JiE1xX8c",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes-channel-thread\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[28,[32,0],[[30,1]],null]],null]],null],null,[[[1,\"        \"],[8,[32,1],null,[[\"@thread\",\"@showFullTitle\"],[[30,2],[30,0,[\"showfullTitle\"]]]],null],[1,\"\\n\\n        \"],[8,[32,2],null,[[\"@thread\",\"@targetMessageId\",\"@includeHeader\",\"@setFullTitle\"],[[30,2],[30,3],true,[30,0,[\"setFullTitle\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@thread\",\"thread\",\"@targetMessageId\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-thread.js",
    "scope": () => [_helper.array, _header.default, _chatThread.default],
    "isStrictMode": true
  }), _ChatRoutesChannelThread), _ChatRoutesChannelThread), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showfullTitle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setFullTitle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setFullTitle"), _class.prototype)), _class);
});