define("discourse/plugins/chat/discourse/models/chat-tracking-state", ["exports", "@glimmer/tracking", "@ember/application", "@ember/service"], function (_exports, _tracking, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatTrackingState = _exports.default = (_class = class ChatTrackingState {
    constructor(owner) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      _initializerDefineProperty(this, "chatTrackingStateManager", _descriptor, this);
      _initializerDefineProperty(this, "_unreadCount", _descriptor2, this);
      _initializerDefineProperty(this, "_mentionCount", _descriptor3, this);
      (0, _application.setOwner)(this, owner);
      this._unreadCount = params.unreadCount || 0;
      this._mentionCount = params.mentionCount || 0;
    }
    reset() {
      this._unreadCount = 0;
      this._mentionCount = 0;
    }
    get unreadCount() {
      return this._unreadCount;
    }
    set unreadCount(value) {
      const valueChanged = this._unreadCount !== value;
      if (valueChanged) {
        this._unreadCount = value;
        this.chatTrackingStateManager.triggerNotificationsChanged();
      }
    }
    get mentionCount() {
      return this._mentionCount;
    }
    set mentionCount(value) {
      const valueChanged = this._mentionCount !== value;
      if (valueChanged) {
        this._mentionCount = value;
        this.chatTrackingStateManager.triggerNotificationsChanged();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatTrackingStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_unreadCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_mentionCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  })), _class);
});