define("discourse/plugins/chat/discourse/components/chat/navbar/new-channel-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/chat/discourse/components/chat/modal/create-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _createChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _ChatNavbarNewChannelButton;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatNavbarNewChannelButton = _exports.default = (_class = (_ChatNavbarNewChannelButton = class ChatNavbarNewChannelButton extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatStateManager", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "modal", _descriptor3, this);
      _initializerDefineProperty(this, "site", _descriptor4, this);
    }
    createChannel() {
      this.modal.show(_createChannel.default);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.currentUser.staff}}
        <DButton
          @action={{this.createChannel}}
          @icon="plus"
          @label={{if this.site.desktopView "chat.create_channel.title"}}
          class="c-navbar__new-channel-button btn-transparent"
        />
      {{/if}}
    
  */
  {
    "id": "PEwkGjH5",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"c-navbar__new-channel-button btn-transparent\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"createChannel\"]],\"plus\",[52,[30,0,[\"site\",\"desktopView\"]],\"chat.create_channel.title\"]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/new-channel-button.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _ChatNavbarNewChannelButton), _ChatNavbarNewChannelButton), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createChannel"), _class.prototype)), _class);
});