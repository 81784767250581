define("discourse/plugins/chat/discourse/modifiers/chat/resizable-node", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier", "discourse-common/utils/decorators"], function (_exports, _destroyable, _runloop, _emberModifier, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const MINIMUM_SIZE = 20;
  let ResizableNode = _exports.default = (_class = class ResizableNode extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "element", null);
      _defineProperty(this, "resizerSelector", null);
      _defineProperty(this, "didResizeContainer", null);
      _defineProperty(this, "options", null);
      _defineProperty(this, "_originalWidth", 0);
      _defineProperty(this, "_originalHeight", 0);
      _defineProperty(this, "_originalX", 0);
      _defineProperty(this, "_originalY", 0);
      _defineProperty(this, "_originalPageX", 0);
      _defineProperty(this, "_originalPageY", 0);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [resizerSelector, didResizeContainer, options = {}] = _ref;
      this.resizerSelector = resizerSelector;
      this.element = element;
      this.didResizeContainer = didResizeContainer;
      this.options = Object.assign({
        vertical: true,
        horizontal: true,
        position: true,
        mutate: true,
        resetOnWindowResize: false
      }, options);
      this.element.querySelector(this.resizerSelector)?.addEventListener("touchstart", this._startResize);
      this.element.querySelector(this.resizerSelector)?.addEventListener("mousedown", this._startResize);
      window.addEventListener("resize", this._resizeWindow);
    }
    cleanup() {
      this.element.querySelector(this.resizerSelector)?.removeEventListener("touchstart", this._startResize);
      this.element.querySelector(this.resizerSelector)?.removeEventListener("mousedown", this._startResize);
      window.removeEventListener("resize", this._resizeWindow);
      (0, _runloop.cancel)(this._throttledResizeHandler);
    }
    _startResize(event) {
      event.preventDefault();
      this._minimumWidth = parseFloat(getComputedStyle(this.element, null).getPropertyValue("min-width").replace("px", "") || MINIMUM_SIZE);
      this._minimumHeight = parseFloat(getComputedStyle(this.element, null).getPropertyValue("min-height").replace("px", "") || MINIMUM_SIZE);
      this._originalWidth = parseFloat(getComputedStyle(this.element, null).getPropertyValue("width").replace("px", ""));
      this._originalHeight = parseFloat(getComputedStyle(this.element, null).getPropertyValue("height").replace("px", ""));
      this._originalX = this.element.getBoundingClientRect().left;
      this._originalY = this.element.getBoundingClientRect().top;
      this._originalPageX = this._eventValueForProperty(event, "pageX");
      this._originalPageY = this._eventValueForProperty(event, "pageY");
      window.addEventListener("touchmove", this._resize);
      window.addEventListener("touchend", this._stopResize);
      window.addEventListener("mousemove", this._resize);
      window.addEventListener("mouseup", this._stopResize);
    }

    /*
      The bulk of the logic is to calculate the new width and height of the element
      based on the current position on page: width is calculated by subtracting
      the difference between the current pageX and the original this._originalPageX
      from the original this._originalWidth, and rounding up to the nearest integer.
      height is calculated in a similar way using pageY and this._originalPageY.
       In this example (B) is the current element top/left and (A) is x/y of the mouse after dragging:
       A------
      |     |
      |  B--|
      |  |  |
      -------
    */
    _resize(event) {
      let width = this._originalWidth;
      let diffWidth = this._eventValueForProperty(event, "pageX") - this._originalPageX;
      if (document.documentElement.classList.contains("rtl")) {
        width = Math.ceil(width + diffWidth);
      } else {
        width = Math.ceil(width - diffWidth);
      }
      const height = Math.ceil(this._originalHeight - (this._eventValueForProperty(event, "pageY") - this._originalPageY));
      const newStyle = {};
      if (this.options.horizontal && width >= this._minimumWidth) {
        newStyle.width = width + "px";
        if (this.options.position) {
          newStyle.left = Math.ceil(this._originalX + (this._eventValueForProperty(event, "pageX") - this._originalPageX)) + "px";
        }
      }
      if (this.options.vertical && height >= this._minimumHeight) {
        newStyle.height = height + "px";
        if (this.options.position) {
          newStyle.top = Math.ceil(this._originalY + (this._eventValueForProperty(event, "pageY") - this._originalPageY)) + "px";
        }
      }
      if (this.options.mutate) {
        Object.assign(this.element.style, newStyle);
      }
      this.didResizeContainer?.(this.element, {
        width: width >= this._minimumWidth ? width : this._minimumWidth,
        height: height >= this._minimumHeight ? height : this._minimumHeight
      });
    }
    _resizeWindow() {
      if (!this.options.resetOnWindowResize) {
        return;
      }
      this._throttledResizeHandler = (0, _runloop.throttle)(this, this._throttledResize, 100);
    }
    _throttledResize() {
      const style = {};
      if (this.options.vertical) {
        style.height = "auto";
      }
      if (this.options.horizontal) {
        style.width = "auto";
      }
      Object.assign(this.element.style, style);
    }
    _stopResize() {
      window.removeEventListener("touchmove", this._resize);
      window.removeEventListener("touchend", this._stopResize);
      window.removeEventListener("mousemove", this._resize);
      window.removeEventListener("mouseup", this._stopResize);
    }
    _eventValueForProperty(event, property) {
      if (event.changedTouches) {
        return event.changedTouches[0][property];
      } else {
        return event[property];
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "_startResize", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "_startResize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_resize", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "_resize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_resizeWindow", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "_resizeWindow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_throttledResize", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "_throttledResize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_stopResize", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "_stopResize"), _class.prototype)), _class);
});