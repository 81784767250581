define("discourse/plugins/chat/discourse/components/chat-composer-dropdown", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse-common/helpers/i18n", "float-kit/components/d-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _dButton, _concatClass, _i18n, _dMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _ChatComposerDropdown;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let ChatComposerDropdown = _exports.default = (_class = (_ChatComposerDropdown = class ChatComposerDropdown extends _component.default {
    onButtonClick(button1, closeFn1) {
      closeFn1();
      button1.action();
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @buttons.length}}
        <DMenu
          class={{concatClass
            "chat-composer-dropdown__trigger-btn"
            "btn-flat"
            (if @hasActivePanel "has-active-panel")
          }}
          @title={{i18n "chat.composer.toggle_toolbar"}}
          @icon="plus"
          @disabled={{@isDisabled}}
          @arrow={{true}}
          @placements={{array "top" "bottom"}}
          @identifier="chat-composer-dropdown__menu"
          ...attributes
          as |menu|
        >
          <ul class="chat-composer-dropdown__list">
            {{#each @buttons as |button|}}
              <li class={{concatClass "chat-composer-dropdown__item" button.id}}>
                <DButton
                  @icon={{button.icon}}
                  @action={{fn this.onButtonClick button menu.close}}
                  @label={{button.label}}
                  class={{concatClass
                    "chat-composer-dropdown__action-btn"
                    button.id
                  }}
                />
              </li>
            {{/each}}
          </ul>
        </DMenu>
      {{/if}}
    
  */
  {
    "id": "6zsHNoy8",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"length\"]],[[[1,\"      \"],[8,[32,0],[[16,0,[28,[32,1],[\"chat-composer-dropdown__trigger-btn\",\"btn-flat\",[52,[30,2],\"has-active-panel\"]],null]],[17,3]],[[\"@title\",\"@icon\",\"@disabled\",\"@arrow\",\"@placements\",\"@identifier\"],[[28,[32,2],[\"chat.composer.toggle_toolbar\"],null],\"plus\",[30,4],true,[28,[32,3],[\"top\",\"bottom\"],null],\"chat-composer-dropdown__menu\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"ul\"],[14,0,\"chat-composer-dropdown__list\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[15,0,[28,[32,1],[\"chat-composer-dropdown__item\",[30,6,[\"id\"]]],null]],[12],[1,\"\\n              \"],[8,[32,4],[[16,0,[28,[32,1],[\"chat-composer-dropdown__action-btn\",[30,6,[\"id\"]]],null]]],[[\"@icon\",\"@action\",\"@label\"],[[30,6,[\"icon\"]],[28,[32,5],[[30,0,[\"onButtonClick\"]],[30,6],[30,5,[\"close\"]]],null],[30,6,[\"label\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[6]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[5]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@buttons\",\"@hasActivePanel\",\"&attrs\",\"@isDisabled\",\"menu\",\"button\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-composer-dropdown.js",
    "scope": () => [_dMenu.default, _concatClass.default, _i18n.default, _helper.array, _dButton.default, _helper.fn],
    "isStrictMode": true
  }), _ChatComposerDropdown), _ChatComposerDropdown), (_applyDecoratedDescriptor(_class.prototype, "onButtonClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onButtonClick"), _class.prototype)), _class);
});