define("discourse/plugins/chat/discourse/components/chat/modal/edit-channel-description", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    class="chat-modal-edit-channel-description"
    @inline={{@inline}}
    @title={{i18n "chat.channel_edit_description_modal.title"}}
    @flash={{this.flash}}
  >
    <:body>
      <span class="chat-modal-edit-channel-description__description">{{i18n
          "chat.channel_edit_description_modal.description"
        }}</span>
      <CharCounter
        @value={{this.editedDescription}}
        @max={{this.descriptionMaxLength}}
      >
        <textarea
          {{on "input" (with-event-value this.onChangeChatChannelDescription)}}
          class="chat-modal-edit-channel-description__description-input"
          placeholder={{i18n
            "chat.channel_edit_description_modal.input_placeholder"
          }}
        >{{this.editedDescription}}</textarea>
      </CharCounter>
    </:body>
    <:footer>
      <DButton
        @action={{this.onSaveChatChannelDescription}}
        @label="save"
        @disabled={{this.isSaveDisabled}}
        class="btn-primary create"
      />
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "9AgN8pbG",
    "block": "[[[8,[39,0],[[24,0,\"chat-modal-edit-channel-description\"]],[[\"@closeModal\",\"@inline\",\"@title\",\"@flash\"],[[30,1],[30,2],[28,[37,1],[\"chat.channel_edit_description_modal.title\"],null],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"chat-modal-edit-channel-description__description\"],[12],[1,[28,[35,1],[\"chat.channel_edit_description_modal.description\"],null]],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@max\"],[[30,0,[\"editedDescription\"]],[30,0,[\"descriptionMaxLength\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[11,\"textarea\"],[24,0,\"chat-modal-edit-channel-description__description-input\"],[16,\"placeholder\",[28,[37,1],[\"chat.channel_edit_description_modal.input_placeholder\"],null]],[4,[38,3],[\"input\",[28,[37,4],[[30,0,[\"onChangeChatChannelDescription\"]]],null]],null],[12],[1,[30,0,[\"editedDescription\"]]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary create\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"onSaveChatChannelDescription\"]],\"save\",[30,0,[\"isSaveDisabled\"]]]],null],[1,\"\\n    \"],[8,[39,6],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@inline\"],false,[\"d-modal\",\"i18n\",\"char-counter\",\"on\",\"with-event-value\",\"d-button\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/modal/edit-channel-description.hbs",
    "isStrictMode": false
  });
  const DESCRIPTION_MAX_LENGTH = 280;
  let ChatModalEditChannelDescription = _exports.default = (_class = class ChatModalEditChannelDescription extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "editedDescription", _descriptor2, this);
      _initializerDefineProperty(this, "flash", _descriptor3, this);
    }
    get channel() {
      return this.args.model;
    }
    get isSaveDisabled() {
      return this.channel.description === this.editedDescription || this.editedDescription?.length > DESCRIPTION_MAX_LENGTH;
    }
    get descriptionMaxLength() {
      return DESCRIPTION_MAX_LENGTH;
    }
    async onSaveChatChannelDescription() {
      try {
        const result = await this.chatApi.updateChannel(this.channel.id, {
          description: this.editedDescription
        });
        this.channel.description = result.channel.description;
        this.args.closeModal();
      } catch (error) {
        this.flash = (0, _ajaxError.extractError)(error);
      }
    }
    onChangeChatChannelDescription(description) {
      this.flash = null;
      this.editedDescription = description;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "editedDescription", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.channel.description || "";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSaveChatChannelDescription", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSaveChatChannelDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeChatChannelDescription", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeChatChannelDescription"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatModalEditChannelDescription);
});