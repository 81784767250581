define("discourse/plugins/chat/discourse/modifiers/chat/scrollable-list", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier", "discourse-common/lib/later", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/helpers/first-visible-message-id"], function (_exports, _destroyable, _runloop, _emberModifier, _later, _decorators, _firstVisibleMessageId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const UP = "up";
  const DOWN = "down";
  let ChatScrollableList = _exports.default = (_class = class ChatScrollableList extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [options] = _ref;
      this.element = element;
      this.options = options;
      this.lastScrollTop = this.computeInitialScrollTop();
      this.element.addEventListener("scroll", this.handleScroll, {
        passive: true
      });
      // listen for wheel events to detect scrolling even when at the top or bottom
      this.element.addEventListener("wheel", this.handleWheel, {
        passive: true
      });
      this.throttleComputeScroll();
    }
    handleScroll() {
      this.throttleComputeScroll();
    }
    handleWheel() {
      this.throttleComputeScroll();
    }
    computeScroll() {
      const scrollTop = this.element.scrollTop;
      this.options.onScroll?.(this.computeState());
      this.lastScrollTop = scrollTop;
    }
    throttleComputeScroll() {
      (0, _runloop.cancel)(this.scrollTimer);
      this.throttleTimer = (0, _runloop.throttle)(this, this.computeScroll, 50, true);
      this.scrollTimer = (0, _later.default)(() => {
        this.options.onScrollEnd?.(Object.assign(this.computeState(), {
          firstVisibleId: (0, _firstVisibleMessageId.default)(this.element)
        }));
      }, this.options.delay || 250);
    }
    cleanup() {
      (0, _runloop.cancel)(this.scrollTimer);
      (0, _runloop.cancel)(this.throttleTimer);
      this.element.removeEventListener("scroll", this.handleScroll);
      this.element.removeEventListener("wheel", this.handleWheel);
    }
    computeState() {
      const direction = this.computeScrollDirection();
      const distanceToBottom = this.computeDistanceToBottom();
      const distanceToTop = this.computeDistanceToTop();
      return {
        up: direction === UP,
        down: direction === DOWN,
        distanceToBottom,
        distanceToTop,
        atBottom: distanceToBottom.pixels <= 1,
        atTop: distanceToTop.pixels <= 1
      };
    }
    computeInitialScrollTop() {
      if (this.options.reverse) {
        return this.element.scrollHeight - this.element.clientHeight;
      } else {
        return this.element.scrollTop;
      }
    }
    computeScrollTop() {
      if (this.options.reverse) {
        return this.element.scrollHeight - this.element.clientHeight - this.element.scrollTop;
      } else {
        return this.element.scrollTop;
      }
    }
    computeDistanceToTop() {
      let pixels;
      const height = this.element.scrollHeight - this.element.clientHeight;
      if (this.options.reverse) {
        pixels = height - Math.abs(this.element.scrollTop);
      } else {
        pixels = Math.abs(this.element.scrollTop);
      }
      return {
        pixels,
        percentage: Math.round(pixels / height * 100)
      };
    }
    computeDistanceToBottom() {
      let pixels;
      const height = this.element.scrollHeight - this.element.clientHeight;
      if (this.options.reverse) {
        pixels = -this.element.scrollTop;
      } else {
        pixels = height - Math.abs(this.element.scrollTop);
      }
      return {
        pixels,
        percentage: Math.round(pixels / height * 100)
      };
    }
    computeScrollDirection() {
      if (this.element.scrollTop === this.lastScrollTop) {
        return null;
      }
      return this.element.scrollTop < this.lastScrollTop ? UP : DOWN;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleScroll", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleWheel", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleWheel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "computeScroll", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "computeScroll"), _class.prototype)), _class);
});