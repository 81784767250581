define("discourse/plugins/chat/discourse/components/collapser", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-message-collapser-header">
    {{this.header}}
  
    {{#if this.collapsed}}
      <DButton
        @action={{this.open}}
        @icon="caret-right"
        class="chat-message-collapser-button chat-message-collapser-closed"
      />
    {{else}}
      <DButton
        @action={{this.close}}
        @icon="caret-down"
        class="chat-message-collapser-button chat-message-collapser-opened"
      />
    {{/if}}
  </div>
  
  <div
    class={{concat-class
      "chat-message-collapser-body"
      (if this.collapsed "hidden")
    }}
  >
    {{yield this.collapsed}}
  </div>
  */
  {
    "id": "hDh7V21u",
    "block": "[[[10,0],[14,0,\"chat-message-collapser-header\"],[12],[1,\"\\n  \"],[1,[30,0,[\"header\"]]],[1,\"\\n\\n\"],[41,[30,0,[\"collapsed\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"chat-message-collapser-button chat-message-collapser-closed\"]],[[\"@action\",\"@icon\"],[[30,0,[\"open\"]],\"caret-right\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],[[24,0,\"chat-message-collapser-button chat-message-collapser-opened\"]],[[\"@action\",\"@icon\"],[[30,0,[\"close\"]],\"caret-down\"]],null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[10,0],[15,0,[28,[37,2],[\"chat-message-collapser-body\",[52,[30,0,[\"collapsed\"]],\"hidden\"]],null]],[12],[1,\"\\n  \"],[18,1,[[30,0,[\"collapsed\"]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"if\",\"d-button\",\"concat-class\",\"yield\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/collapser.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_obj = {
    tagName: "",
    collapsed: false,
    header: null,
    onToggle: null,
    open() {
      this.set("collapsed", false);
      this.onToggle?.(false);
    },
    close() {
      this.set("collapsed", true);
      this.onToggle?.(true);
    }
  }, (_applyDecoratedDescriptor(_obj, "open", [_object.action], Object.getOwnPropertyDescriptor(_obj, "open"), _obj), _applyDecoratedDescriptor(_obj, "close", [_object.action], Object.getOwnPropertyDescriptor(_obj, "close"), _obj)), _obj)));
});