define("discourse/plugins/chat/discourse/modifiers/chat/on-scroll", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier", "discourse-common/utils/decorators"], function (_exports, _destroyable, _runloop, _emberModifier, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let ChatOnScroll = _exports.default = (_class = class ChatOnScroll extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [callback, options] = _ref;
      this.element = element;
      this.callback = callback;
      this.options = options;
      this.element.addEventListener("scroll", this.throttledCallback, {
        passive: true
      });
    }
    throttledCallback(event) {
      this.throttledHandler = (0, _runloop.throttle)(this, this.callback, event, this.options.delay ?? 100, this.options.immediate ?? false);
    }
    cleanup() {
      (0, _runloop.cancel)(this.throttledHandler);
      this.element.removeEventListener("scroll", this.throttledCallback);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "throttledCallback", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "throttledCallback"), _class.prototype)), _class);
});