define("discourse/plugins/chat/discourse/components/chat/modal/archive-channel", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-common/lib/later", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-to-topic-selector", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _template2, _utils, _dButton, _dModal, _ajaxError, _i18n, _later, _discourseI18n, _chatToTopicSelector, _chatChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _ChatModalArchiveChannel;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatModalArchiveChannel = _exports.default = (_class = (_ChatModalArchiveChannel = class ChatModalArchiveChannel extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "selection", _descriptor3, this);
      _initializerDefineProperty(this, "saving", _descriptor4, this);
      _initializerDefineProperty(this, "topicTitle", _descriptor5, this);
      _initializerDefineProperty(this, "categoryId", _descriptor6, this);
      _initializerDefineProperty(this, "tags", _descriptor7, this);
      _initializerDefineProperty(this, "selectedTopicId", _descriptor8, this);
      _initializerDefineProperty(this, "flash", _descriptor9, this);
      _initializerDefineProperty(this, "flashType", _descriptor10, this);
    }
    get channel() {
      return this.args.model.channel;
    }
    get newTopic() {
      return this.selection === _chatToTopicSelector.NEW_TOPIC_SELECTION;
    }
    get existingTopic() {
      return this.selection === _chatToTopicSelector.EXISTING_TOPIC_SELECTION;
    }
    get buttonDisabled() {
      if (this.saving) {
        return true;
      }
      if (this.newTopic && (!this.topicTitle || this.topicTitle.length < this.siteSettings.min_topic_title_length || this.topicTitle.length > this.siteSettings.max_topic_title_length)) {
        return true;
      }
      if (this.existingTopic && (0, _utils.isEmpty)(this.selectedTopicId)) {
        return true;
      }
      return false;
    }
    get instructionLabels() {
      const labels1 = {};
      labels1[_chatToTopicSelector.NEW_TOPIC_SELECTION] = _discourseI18n.default.t("chat.selection.new_topic.instructions_channel_archive");
      labels1[_chatToTopicSelector.EXISTING_TOPIC_SELECTION] = _discourseI18n.default.t("chat.selection.existing_topic.instructions_channel_archive");
      return labels1;
    }
    get instructionsText() {
      return (0, _template2.htmlSafe)(_discourseI18n.default.t("chat.channel_archive.instructions", {
        channelTitle: this.channel.escapedTitle
      }));
    }
    get data() {
      const data1 = {
        type: this.selection
      };
      if (this.newTopic) {
        data1.title = this.topicTitle;
        data1.category_id = this.categoryId;
        data1.tags = this.tags;
      }
      if (this.existingTopic) {
        data1.topic_id = this.selectedTopicId;
      }
      return data1;
    }
    archiveChannel() {
      this.saving = true;
      return this.chatApi.createChannelArchive(this.channel.id, this.data).then(() => {
        this.flash = _discourseI18n.default.t("chat.channel_archive.process_started");
        this.flashType = "success";
        this.channel.status = _chatChannel.CHANNEL_STATUSES.archived;
        (0, _later.default)(() => {
          this.args.closeModal();
        }, 3000);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.saving = false);
    }
    newTopicSelected(topic1) {
      this.selectedTopicId = topic1.id;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @closeModal={{@closeModal}}
        class="chat-modal-archive-channel"
        @inline={{@inline}}
        @title={{i18n "chat.channel_archive.title"}}
        @flash={{this.flash}}
        @flashType={{this.flashType}}
      >
        <:body>
          <p class="chat-modal-archive-channel__instructions">
            {{this.instructionsText}}
          </p>
          <ChatToTopicSelector
            @selection={{this.selection}}
            @topicTitle={{this.topicTitle}}
            @categoryId={{this.categoryId}}
            @tags={{this.tags}}
            @topicChangedCallback={{this.newTopicSelected}}
            @instructionLabels={{this.instructionLabels}}
            @allowNewMessage={{false}}
          />
        </:body>
        <:footer>
          <DButton
            @disabled={{this.buttonDisabled}}
            @action={{this.archiveChannel}}
            @label="chat.channel_archive.title"
            id="chat-confirm-archive-channel"
            class="btn-primary"
          />
        </:footer>
      </DModal>
    
  */
  {
    "id": "P2TrWr8v",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-archive-channel\"]],[[\"@closeModal\",\"@inline\",\"@title\",\"@flash\",\"@flashType\"],[[30,1],[30,2],[28,[32,1],[\"chat.channel_archive.title\"],null],[30,0,[\"flash\"]],[30,0,[\"flashType\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,2],[14,0,\"chat-modal-archive-channel__instructions\"],[12],[1,\"\\n          \"],[1,[30,0,[\"instructionsText\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,2],null,[[\"@selection\",\"@topicTitle\",\"@categoryId\",\"@tags\",\"@topicChangedCallback\",\"@instructionLabels\",\"@allowNewMessage\"],[[30,0,[\"selection\"]],[30,0,[\"topicTitle\"]],[30,0,[\"categoryId\"]],[30,0,[\"tags\"]],[30,0,[\"newTopicSelected\"]],[30,0,[\"instructionLabels\"]],false]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,1,\"chat-confirm-archive-channel\"],[24,0,\"btn-primary\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[30,0,[\"archiveChannel\"]],\"chat.channel_archive.title\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/archive-channel.js",
    "scope": () => [_dModal.default, _i18n.default, _chatToTopicSelector.default, _dButton.default],
    "isStrictMode": true
  }), _ChatModalArchiveChannel), _ChatModalArchiveChannel), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _chatToTopicSelector.NEW_TOPIC_SELECTION;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "topicTitle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "categoryId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tags", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedTopicId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "flashType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "archiveChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "archiveChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newTopicSelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newTopicSelected"), _class.prototype)), _class);
});