define("discourse/plugins/chat/discourse/components/channel-name/index", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "@ember/template", "discourse/components/plugin-outlet", "discourse/components/user-status-message", "discourse/helpers/replace-emoji", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _template2, _pluginOutlet, _userStatusMessage, _replaceEmoji, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatChannelName;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatChannelName = _exports.default = (_class = (_ChatChannelName = class ChatChannelName extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
    }
    get firstUser() {
      return this.args.channel.chatable.users[0];
    }
    get users() {
      return this.args.channel.chatable.users;
    }
    get groupDirectMessage() {
      return this.args.channel.isDirectMessageChannel && this.args.channel.chatable.group;
    }
    get groupsDirectMessageTitle() {
      return this.args.channel.title || this.usernames;
    }
    get usernames() {
      return this.users.mapBy("username").join(", ");
    }
    get channelColorStyle() {
      return (0, _template2.htmlSafe)(`color: #${this.args.channel.chatable.color}`);
    }
    get showUserStatus() {
      return !!(this.users.length === 1 && this.users[0].status);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-channel-name">
        {{#if @channel.isDirectMessageChannel}}
          {{#if this.groupDirectMessage}}
            <span class="chat-channel-name__label">
              {{this.groupsDirectMessageTitle}}
            </span>
          {{else}}
            <span class="chat-channel-name__label">
              {{this.firstUser.username}}
            </span>
            {{#if this.showUserStatus}}
              <UserStatusMessage
                @status={{get this.users "0.status"}}
                @showDescription={{if this.site.mobileView "true"}}
                class="chat-channel__user-status-message"
              />
            {{/if}}
            <PluginOutlet
              @name="after-chat-channel-username"
              @outletArgs={{hash user=@user}}
              @tagName=""
              @connectorTagName=""
            />
          {{/if}}
        {{else if @channel.isCategoryChannel}}
          <span class="chat-channel-name__label">
            {{replaceEmoji @channel.title}}
          </span>
  
          {{#if (has-block)}}
            {{yield}}
          {{/if}}
        {{/if}}
      </div>
    
  */
  {
    "id": "cWA51D6O",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-channel-name\"],[12],[1,\"\\n\"],[41,[30,1,[\"isDirectMessageChannel\"]],[[[41,[30,0,[\"groupDirectMessage\"]],[[[1,\"          \"],[10,1],[14,0,\"chat-channel-name__label\"],[12],[1,\"\\n            \"],[1,[30,0,[\"groupsDirectMessageTitle\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[14,0,\"chat-channel-name__label\"],[12],[1,\"\\n            \"],[1,[30,0,[\"firstUser\",\"username\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"],[41,[30,0,[\"showUserStatus\"]],[[[1,\"            \"],[8,[32,0],[[24,0,\"chat-channel__user-status-message\"]],[[\"@status\",\"@showDescription\"],[[28,[32,1],[[30,0,[\"users\"]],\"0.status\"],null],[52,[30,0,[\"site\",\"mobileView\"]],\"true\"]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[8,[32,2],null,[[\"@name\",\"@outletArgs\",\"@tagName\",\"@connectorTagName\"],[\"after-chat-channel-username\",[28,[32,3],null,[[\"user\"],[[30,2]]]],\"\",\"\"]],null],[1,\"\\n\"]],[]]]],[]],[[[41,[30,1,[\"isCategoryChannel\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-channel-name__label\"],[12],[1,\"\\n          \"],[1,[28,[32,4],[[30,1,[\"title\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[48,[30,3]],[[[1,\"          \"],[18,3,null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]],null]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@channel\",\"@user\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/channel-name/index.js",
    "scope": () => [_userStatusMessage.default, _helper.get, _pluginOutlet.default, _helper.hash, _replaceEmoji.default],
    "isStrictMode": true
  }), _ChatChannelName), _ChatChannelName), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});