define("discourse/plugins/chat/discourse/components/chat/navbar/open-drawer-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _url, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatNavbarOpenDrawerButton;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatNavbarOpenDrawerButton = _exports.default = (_class = (_ChatNavbarOpenDrawerButton = class ChatNavbarOpenDrawerButton extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatStateManager", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
    }
    async openDrawer() {
      this.chatStateManager.prefersDrawer();
      _url.default.routeTo(this.chatStateManager.lastKnownAppURL).then(() => {
        _url.default.routeTo(this.chatStateManager.lastKnownChatURL);
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.site.desktopView}}
        <DButton
          @icon="discourse-compress"
          @title="chat.close_full_page"
          class="c-navbar__open-drawer-button btn-transparent"
          @action={{this.openDrawer}}
        />
      {{/if}}
    
  */
  {
    "id": "rzfCPs7o",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"c-navbar__open-drawer-button btn-transparent\"]],[[\"@icon\",\"@title\",\"@action\"],[\"discourse-compress\",\"chat.close_full_page\",[30,0,[\"openDrawer\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/open-drawer-button.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _ChatNavbarOpenDrawerButton), _ChatNavbarOpenDrawerButton), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openDrawer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDrawer"), _class.prototype)), _class);
});