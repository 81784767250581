define("discourse/plugins/chat/discourse/components/chat/routes/channel", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-side-panel", "discourse/plugins/chat/discourse/components/full-page-chat", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _navbar, _chatSidePanel, _fullPageChat, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatRoutesChannel;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatRoutesChannel = _exports.default = (_class = (_ChatRoutesChannel = class ChatRoutesChannel extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
    get getChannelsRoute() {
      return this.args.channel.isDirectMessageChannel ? "chat.direct-messages" : "chat.channels";
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-routes-channel">
        <Navbar as |navbar|>
          {{#if this.site.mobileView}}
            <navbar.BackButton @route={{this.getChannelsRoute}} />
          {{/if}}
          <navbar.ChannelTitle @channel={{@channel}} />
          <navbar.Actions as |action|>
            <action.OpenDrawerButton />
            <action.ThreadsListButton @channel={{@channel}} />
          </navbar.Actions>
        </Navbar>
  
        <FullPageChat
          @channel={{@channel}}
          @targetMessageId={{@targetMessageId}}
        />
      </div>
  
      <SidePanel>
        {{outlet}}
      </SidePanel>
    
  */
  {
    "id": "1ezSWffb",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes-channel\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"          \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\"],[[30,0,[\"getChannelsRoute\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[8,[30,1,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,3,[\"OpenDrawerButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,3,[\"ThreadsListButton\"]],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n        \"]],[3]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,1],null,[[\"@channel\",\"@targetMessageId\"],[[30,2],[30,4]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[32,2],null,null,[[\"default\"],[[[[1,\"\\n      \"],[46,[28,[31,2],null,null],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"navbar\",\"@channel\",\"action\",\"@targetMessageId\"],false,[\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel.js",
    "scope": () => [_navbar.default, _fullPageChat.default, _chatSidePanel.default],
    "isStrictMode": true
  }), _ChatRoutesChannel), _ChatRoutesChannel), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});