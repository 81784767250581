define("discourse/plugins/chat/discourse/components/chat/message-creator/index", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/plugins/chat/discourse/components/chat/message-creator/add-members", "discourse/plugins/chat/discourse/components/chat/message-creator/constants", "discourse/plugins/chat/discourse/components/chat/message-creator/new-group", "discourse/plugins/chat/discourse/components/chat/message-creator/search", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _addMembers, _constants, _newGroup, _search, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatMessageCreator;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatMessageCreator = _exports.default = (_class = (_ChatMessageCreator = class ChatMessageCreator extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "mode", _descriptor, this);
      _initializerDefineProperty(this, "members", _descriptor2, this);
    }
    get componentForMode() {
      switch (this.args.mode ?? this.mode) {
        case _constants.MODES.search:
          return _search.default;
        case _constants.MODES.new_group:
          return _newGroup.default;
        case _constants.MODES.add_members:
          return _addMembers.default;
      }
    }
    changeMode(mode1) {
      let members1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      this.mode = mode1;
      this.changeMembers(members1);
    }
    changeMembers(members1) {
      this.members = members1;
    }
    cancelAction() {
      return this.args.onCancel?.() || this.changeMode(_constants.MODES.search);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-message-creator-container">
        <div class="chat-message-creator">
          <this.componentForMode
            @channel={{@channel}}
            @onChangeMode={{this.changeMode}}
            @onChangeMembers={{this.changeMembers}}
            @close={{@onClose}}
            @cancel={{this.cancelAction}}
            @members={{this.members}}
          />
        </div>
      </div>
    
  */
  {
    "id": "KOmH179J",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator\"],[12],[1,\"\\n        \"],[8,[30,0,[\"componentForMode\"]],null,[[\"@channel\",\"@onChangeMode\",\"@onChangeMembers\",\"@close\",\"@cancel\",\"@members\"],[[30,1],[30,0,[\"changeMode\"]],[30,0,[\"changeMembers\"]],[30,2],[30,0,[\"cancelAction\"]],[30,0,[\"members\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@channel\",\"@onClose\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/index.js",
    "isStrictMode": true
  }), _ChatMessageCreator), _ChatMessageCreator), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "mode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _constants.MODES.search;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "members", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeMode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeMembers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeMembers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelAction"), _class.prototype)), _class);
});