define("discourse/plugins/chat/discourse/components/chat-channel-message-emoji-picker", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@popperjs/core", "ember-modifier", "discourse/lib/offset-calculator", "discourse/plugins/chat/discourse/components/chat-emoji-picker", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _core, _emberModifier, _offsetCalculator, _chatEmojiPicker, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatChannelMessageEmojiPicker;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatChannelMessageEmojiPicker = _exports.default = (_class = (_ChatChannelMessageEmojiPicker = class ChatChannelMessageEmojiPicker extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "chatEmojiPickerManager", _descriptor2, this);
      _defineProperty(this, "context", "chat-channel-message");
      _defineProperty(this, "listenToBodyScroll", (0, _emberModifier.modifier)(() => {
        const handler1 = () => {
          this.chatEmojiPickerManager.close();
        };
        document.addEventListener("scroll", handler1);
        return () => {
          document.removeEventListener("scroll", handler1);
        };
      }));
    }
    didSelectEmoji(emoji1) {
      this.chatEmojiPickerManager.picker?.didSelectEmoji(emoji1);
      this.chatEmojiPickerManager.close();
    }
    didInsert(element1) {
      if (this.site.mobileView) {
        element1.classList.remove("hidden");
        return;
      }
      this._popper = (0, _core.createPopper)(this.chatEmojiPickerManager.picker?.trigger, element1, {
        placement: "top",
        modifiers: [{
          name: "eventListeners",
          options: {
            scroll: false,
            resize: false
          }
        }, {
          name: "flip",
          options: {
            padding: {
              top: (0, _offsetCalculator.headerOffset)()
            }
          }
        }]
      });
      element1.classList.remove("hidden");
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this._popper?.destroy();
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <ChatEmojiPicker
        {{this.listenToBodyScroll}}
        @context="chat-channel-message"
        @didInsert={{this.didInsert}}
        @willDestroy={{this.willDestroy}}
        @didSelectEmoji={{this.didSelectEmoji}}
        class="hidden"
      />
    
  */
  {
    "id": "7uySDSKx",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"hidden\"],[4,[30,0,[\"listenToBodyScroll\"]],null,null]],[[\"@context\",\"@didInsert\",\"@willDestroy\",\"@didSelectEmoji\"],[\"chat-channel-message\",[30,0,[\"didInsert\"]],[30,0,[\"willDestroy\"]],[30,0,[\"didSelectEmoji\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-message-emoji-picker.js",
    "scope": () => [_chatEmojiPicker.default],
    "isStrictMode": true
  }), _ChatChannelMessageEmojiPicker), _ChatChannelMessageEmojiPicker), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatEmojiPickerManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didSelectEmoji", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didSelectEmoji"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype)), _class);
});