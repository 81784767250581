define("discourse/plugins/chat/discourse/lib/chat-messages-manager", ["exports", "@glimmer/tracking", "@ember/application"], function (_exports, _tracking, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatMessagesManager = _exports.default = (_class = class ChatMessagesManager {
    constructor(owner) {
      _initializerDefineProperty(this, "messages", _descriptor, this);
      (0, _application.setOwner)(this, owner);
    }
    get stagedMessages() {
      return this.messages.filterBy("staged");
    }
    get selectedMessages() {
      return this.messages.filterBy("selected");
    }
    clearSelectedMessages() {
      this.selectedMessages.forEach(message => message.selected = false);
    }
    clear() {
      this.messages = [];
    }
    addMessages() {
      let messages = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      this.messages = this.messages.concat(messages).uniqBy("id").sort((a, b) => a.createdAt - b.createdAt);
    }
    findMessage(messageId) {
      return this.messages.find(message => message.id === parseInt(messageId, 10));
    }
    findFirstMessageOfDay(a) {
      return this.messages.find(b => a.getFullYear() === b.createdAt.getFullYear() && a.getMonth() === b.createdAt.getMonth() && a.getDate() === b.createdAt.getDate());
    }
    removeMessage(message) {
      return this.messages.removeObject(message);
    }
    findStagedMessage(stagedMessageId) {
      return this.stagedMessages.find(message => message.id === stagedMessageId);
    }
    findIndexOfMessage(id) {
      return this.messages.findIndex(m => m.id === id);
    }
    findLastMessage() {
      return this.messages.findLast(message => !message.deletedAt);
    }
    findLastUserMessage(user) {
      return this.messages.findLast(message => message.user.id === user.id && !message.deletedAt);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "messages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "stagedMessages", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "stagedMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedMessages", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "selectedMessages"), _class.prototype)), _class);
});