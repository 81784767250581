define("discourse/plugins/chat/discourse/components/chat/navbar/threads-list-button", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "I18n", "discourse/plugins/chat/discourse/components/chat/thread/header-unread-indicator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _concatClass, _dIcon, _I18n, _headerUnreadIndicator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatNavbarThreadsListButton;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatNavbarThreadsListButton = _exports.default = (_class = (_ChatNavbarThreadsListButton = class ChatNavbarThreadsListButton extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "threadsListLabel", _I18n.default.t("chat.threads.list"));
    }
    get showThreadsListButton() {
      return this.args.channel?.threadingEnabled && this.router.currentRoute.name !== "chat.channel.threads" && this.router.currentRoute.name !== "chat.channel.thread" && this.router.currentRoute.name !== "chat.channel.thread.index";
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.showThreadsListButton}}
        <LinkTo
          @route="chat.channel.threads"
          @models={{@channel.routeModels}}
          title={{this.threadsListLabel}}
          class={{concatClass
            "c-navbar__threads-list-button"
            "btn"
            "no-text"
            "btn-flat"
            (if @channel.threadsManager.unreadThreadCount "has-unreads")
          }}
        >
          {{icon "discourse-threads"}}
          <ThreadHeaderUnreadIndicator @channel={{@channel}} />
        </LinkTo>
      {{/if}}
    
  */
  {
    "id": "HrF2otwx",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"showThreadsListButton\"]],[[[1,\"      \"],[8,[32,0],[[16,\"title\",[30,0,[\"threadsListLabel\"]]],[16,0,[28,[32,1],[\"c-navbar__threads-list-button\",\"btn\",\"no-text\",\"btn-flat\",[52,[30,1,[\"threadsManager\",\"unreadThreadCount\"]],\"has-unreads\"]],null]]],[[\"@route\",\"@models\"],[\"chat.channel.threads\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,2],[\"discourse-threads\"],null]],[1,\"\\n        \"],[8,[32,3],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/threads-list-button.js",
    "scope": () => [_routing.LinkTo, _concatClass.default, _dIcon.default, _headerUnreadIndicator.default],
    "isStrictMode": true
  }), _ChatNavbarThreadsListButton), _ChatNavbarThreadsListButton), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});