define("discourse/plugins/chat/discourse/components/user-threads/index", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/routing", "@ember/service", "discourse-common/helpers/i18n", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/components/channel-icon", "discourse/plugins/chat/discourse/components/channel-title", "discourse/plugins/chat/discourse/components/chat/list", "discourse/plugins/chat/discourse/components/chat-message-thread-indicator", "discourse/plugins/chat/discourse/components/thread-title", "discourse/plugins/chat/discourse/components/user-threads/preview", "discourse/plugins/chat/discourse/models/chat-thread-preview", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _routing, _service, _i18n, _decorators, _channelIcon, _channelTitle, _list, _chatMessageThreadIndicator, _threadTitle, _preview, _chatThreadPreview, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _UserThreads;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UserThreads = _exports.default = (_class = (_UserThreads = class UserThreads extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor3, this);
      _initializerDefineProperty(this, "messageBus", _descriptor4, this);
      _initializerDefineProperty(this, "site", _descriptor5, this);
      _defineProperty(this, "trackedChannels", {});
    }
    get threadsCollection() {
      return this.chatApi.userThreads(this.handleLoadedThreads);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      Object.keys(this.trackedChannels).forEach(id1 => {
        this.messageBus.unsubscribe(`/chat/${id1}`, this.onMessage);
      });
      this.trackedChannels = {};
    }
    handleLoadedThreads(result1) {
      return result1.threads.map(threadObject1 => {
        const channel1 = this.chatChannelsManager.store(threadObject1.channel);
        const thread1 = channel1.threadsManager.add(channel1, threadObject1);
        const tracking1 = result1.tracking[thread1.id];
        if (tracking1) {
          thread1.tracking.mentionCount = tracking1.mention_count;
          thread1.tracking.unreadCount = tracking1.unread_count;
        }
        this.trackChannel(thread1.channel);
        return thread1;
      });
    }
    trackChannel(channel1) {
      if (this.trackedChannels[channel1.id]) {
        return;
      }
      this.trackedChannels[channel1.id] = channel1;
      this.messageBus.subscribe(`/chat/${channel1.id}`, this.onMessage, channel1.channelMessageBusLastId);
    }
    onMessage(data1) {
      if (data1.type === "update_thread_original_message") {
        const channel1 = this.trackedChannels[data1.channel_id];
        if (!channel1) {
          return;
        }
        const thread1 = channel1.threadsManager.threads.find(t1 => t1.id === data1.thread_id);
        if (thread1) {
          thread1.preview = _chatThreadPreview.default.create(data1.preview);
        }
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <List
        @collection={{this.threadsCollection}}
        class="c-user-threads"
        as |list|
      >
        <list.Item as |thread|>
          <div class="c-user-thread" data-id={{thread.id}}>
            {{#if this.site.mobileView}}
              <ChannelIcon @thread={{thread}} />
            {{/if}}
  
            {{#if this.site.mobileView}}
              <LinkTo
                class="c-user-thread__link"
                @route="chat.channel.thread"
                @models={{thread.routeModels}}
              >
                <ChannelTitle @channel={{thread.channel}} />
                <ThreadTitle @thread={{thread}} />
  
                <ThreadPreview @preview={{thread.preview}} />
              </LinkTo>
            {{else}}
              <ChannelTitle @channel={{thread.channel}} />
              <ThreadTitle @thread={{thread}} />
  
              <ThreadIndicator
                @message={{thread.originalMessage}}
                @interactiveUser={{false}}
                @interactiveThread={{false}}
                tabindex="-1"
              />
            {{/if}}
          </div>
        </list.Item>
        <list.EmptyState>
          <div class="empty-state-threads">
            {{i18n "chat.empty_state.my_threads"}}
          </div>
        </list.EmptyState>
      </List>
    
  */
  {
    "id": "fPGEq7KN",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"c-user-threads\"]],[[\"@collection\"],[[30,0,[\"threadsCollection\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Item\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"c-user-thread\"],[15,\"data-id\",[30,2,[\"id\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"            \"],[8,[32,1],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"            \"],[8,[32,2],[[24,0,\"c-user-thread__link\"]],[[\"@route\",\"@models\"],[\"chat.channel.thread\",[30,2,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[8,[32,3],null,[[\"@channel\"],[[30,2,[\"channel\"]]]],null],[1,\"\\n              \"],[8,[32,4],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n\\n              \"],[8,[32,5],null,[[\"@preview\"],[[30,2,[\"preview\"]]]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[32,3],null,[[\"@channel\"],[[30,2,[\"channel\"]]]],null],[1,\"\\n            \"],[8,[32,4],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n\\n            \"],[8,[32,6],[[24,\"tabindex\",\"-1\"]],[[\"@message\",\"@interactiveUser\",\"@interactiveThread\"],[[30,2,[\"originalMessage\"]],false,false]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n      \"]],[2]]]]],[1,\"\\n      \"],[8,[30,1,[\"EmptyState\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"empty-state-threads\"],[12],[1,\"\\n          \"],[1,[28,[32,7],[\"chat.empty_state.my_threads\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"]],[\"list\",\"thread\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/user-threads/index.js",
    "scope": () => [_list.default, _channelIcon.default, _routing.LinkTo, _channelTitle.default, _threadTitle.default, _preview.default, _chatMessageThreadIndicator.default, _i18n.default],
    "isStrictMode": true
  }), _UserThreads), _UserThreads), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "threadsCollection", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "threadsCollection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleLoadedThreads", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleLoadedThreads"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onMessage"), _class.prototype)), _class);
});