define("discourse/plugins/chat/discourse/components/chat-retention-reminder", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/chat/discourse/components/chat-retention-reminder-text", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajax, _ajaxError, _chatRetentionReminderText, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatRetentionReminder;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatRetentionReminder = _exports.default = (_class = (_ChatRetentionReminder = class ChatRetentionReminder extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
    }
    get show() {
      return this.args.channel?.isDirectMessageChannel && this.currentUser?.get("needs_dm_retention_reminder") || this.args.channel?.isCategoryChannel && this.currentUser?.get("needs_channel_retention_reminder");
    }
    async dismiss() {
      try {
        await (0, _ajax.ajax)("/chat/dismiss-retention-reminder", {
          method: "POST",
          data: {
            chatable_type: this.args.channel.chatableType
          }
        });
        const field1 = this.args.channel.isDirectMessageChannel ? "needs_dm_retention_reminder" : "needs_channel_retention_reminder";
        this.currentUser.set(field1, false);
      } catch (e1) {
        (0, _ajaxError.popupAjaxError)(e1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.show}}
        <div class="chat-retention-reminder">
          <ChatRetentionReminderText @channel={{@channel}} />
          <DButton
            @action={{this.dismiss}}
            @icon="times"
            class="no-text btn-icon btn-transparent dismiss-btn"
          />
        </div>
      {{/if}}
    
  */
  {
    "id": "nbOhEYvT",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-retention-reminder\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n        \"],[8,[32,1],[[24,0,\"no-text btn-icon btn-transparent dismiss-btn\"]],[[\"@action\",\"@icon\"],[[30,0,[\"dismiss\"]],\"times\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-retention-reminder.js",
    "scope": () => [_chatRetentionReminderText.default, _dButton.default],
    "isStrictMode": true
  }), _ChatRetentionReminder), _ChatRetentionReminder), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dismiss", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype)), _class);
});