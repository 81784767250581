define("discourse/plugins/chat/discourse/components/chat/thread/header", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/noop", "discourse/helpers/replace-emoji", "discourse-common/helpers/d-icon", "discourse-i18n", "truth-helpers/helpers/and", "discourse/plugins/chat/discourse/components/chat/modal/thread-settings", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat/thread/header-unread-indicator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _noop, _replaceEmoji, _dIcon, _discourseI18n, _and, _threadSettings, _navbar, _headerUnreadIndicator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _ChatThreadHeader;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatThreadHeader = _exports.default = (_class = (_ChatThreadHeader = class ChatThreadHeader extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "chatHistory", _descriptor2, this);
      _initializerDefineProperty(this, "modal", _descriptor3, this);
      _initializerDefineProperty(this, "site", _descriptor4, this);
    }
    get backLink() {
      const prevPage1 = this.chatHistory.previousRoute?.name;
      let route1, title1, models1;
      if (prevPage1 === "chat.channel.threads") {
        route1 = "chat.channel.threads";
        title1 = _discourseI18n.default.t("chat.return_to_threads_list");
        models1 = this.channel?.routeModels;
      } else if (prevPage1 === "chat.channel.index" && this.site.desktopView) {
        route1 = "chat.channel.threads";
        title1 = _discourseI18n.default.t("chat.return_to_threads_list");
        models1 = this.channel?.routeModels;
      } else if (prevPage1 === "chat.threads") {
        route1 = "chat.threads";
        title1 = _discourseI18n.default.t("chat.my_threads.title");
        models1 = [];
      } else if (!this.currentUser.isInDoNotDisturb() && this.unreadCount > 0) {
        route1 = "chat.channel.threads";
        title1 = _discourseI18n.default.t("chat.return_to_threads_list");
        models1 = this.channel?.routeModels;
      } else {
        route1 = "chat.channel.index";
        title1 = _discourseI18n.default.t("chat.return_to_channel");
        models1 = this.channel?.routeModels;
      }
      return {
        route: route1,
        models: models1,
        title: title1
      };
    }
    get channel() {
      return this.args.thread?.channel;
    }
    get headerTitle() {
      return this.args.thread?.title ?? _discourseI18n.default.t("chat.thread.label");
    }
    get unreadCount() {
      return this.channel?.threadsManager?.unreadThreadCount;
    }
    get showThreadUnreadIndicator() {
      return this.backLink.route === "chat.channel.threads" && this.unreadCount > 0;
    }
    get openThreadTitleModal() {
      if (this.currentUser.admin || this.currentUser.id === this.args.thread?.originalMessage?.user?.id) {
        return () => this.modal.show(_threadSettings.default, {
          model: this.args.thread
        });
      } else {
        return _noop.default;
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <Navbar @showFullTitle={{@showFullTitle}} as |navbar|>
        {{#if (and this.channel.threadingEnabled @thread)}}
          <navbar.BackButton
            @route={{this.backLink.route}}
            @routeModels={{this.backLink.models}}
            @title={{this.backLink.title}}
          >
            {{#if this.showThreadUnreadIndicator}}
              <ChatThreadHeaderUnreadIndicator @channel={{this.channel}} />
            {{/if}}
            {{icon "chevron-left"}}
          </navbar.BackButton>
        {{/if}}
  
        <navbar.Title
          @title={{replaceEmoji this.headerTitle}}
          @openThreadTitleModal={{this.openThreadTitleModal}}
        />
        <navbar.Actions as |action|>
          <action.ThreadTrackingDropdown @thread={{@thread}} />
          <action.ThreadSettingsButton @thread={{@thread}} />
          <action.CloseThreadButton @thread={{@thread}} />
        </navbar.Actions>
      </Navbar>
    
  */
  {
    "id": "iUgDQ5tW",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@showFullTitle\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[32,1],[[30,0,[\"channel\",\"threadingEnabled\"]],[30,3]],null],[[[1,\"        \"],[8,[30,2,[\"BackButton\"]],null,[[\"@route\",\"@routeModels\",\"@title\"],[[30,0,[\"backLink\",\"route\"]],[30,0,[\"backLink\",\"models\"]],[30,0,[\"backLink\",\"title\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"showThreadUnreadIndicator\"]],[[[1,\"            \"],[8,[32,2],null,[[\"@channel\"],[[30,0,[\"channel\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[1,[28,[32,3],[\"chevron-left\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[30,2,[\"Title\"]],null,[[\"@title\",\"@openThreadTitleModal\"],[[28,[32,4],[[30,0,[\"headerTitle\"]]],null],[30,0,[\"openThreadTitleModal\"]]]],null],[1,\"\\n      \"],[8,[30,2,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"ThreadTrackingDropdown\"]],null,[[\"@thread\"],[[30,3]]],null],[1,\"\\n        \"],[8,[30,4,[\"ThreadSettingsButton\"]],null,[[\"@thread\"],[[30,3]]],null],[1,\"\\n        \"],[8,[30,4,[\"CloseThreadButton\"]],null,[[\"@thread\"],[[30,3]]],null],[1,\"\\n      \"]],[4]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[\"@showFullTitle\",\"navbar\",\"@thread\",\"action\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/thread/header.js",
    "scope": () => [_navbar.default, _and.default, _headerUnreadIndicator.default, _dIcon.default, _replaceEmoji.default],
    "isStrictMode": true
  }), _ChatThreadHeader), _ChatThreadHeader), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatHistory", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});