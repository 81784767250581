define("discourse/plugins/chat/discourse/components/chat/navbar/back-button", ["exports", "@glimmer/component", "@ember/routing", "discourse-common/helpers/d-icon", "I18n", "discourse/plugins/chat/discourse/lib/chat-constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _dIcon, _I18n, _chatConstants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _ChatNavbarBackButton;
  class ChatNavbarBackButton extends _component.default {
    get icon() {
      return this.args.icon ?? "chevron-left";
    }
    get title() {
      return this.args.title ?? _I18n.default.t("chat.browse.back");
    }
    get targetRoute() {
      if (_chatConstants.FOOTER_NAV_ROUTES.includes(this.args.route)) {
        return this.args.route;
      } else {
        return "chat";
      }
    }
  }
  _exports.default = ChatNavbarBackButton;
  _ChatNavbarBackButton = ChatNavbarBackButton;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @routeModels}}
        <LinkTo
          @route={{@route}}
          @models={{@routeModels}}
          class="c-navbar__back-button no-text btn-transparent btn"
          title={{this.title}}
        >
          {{#if (has-block)}}
            {{yield}}
          {{else}}
            {{icon this.icon}}
          {{/if}}
        </LinkTo>
      {{else}}
        <LinkTo
          @route={{this.targetRoute}}
          class="c-navbar__back-button no-text btn-transparent btn"
          title={{this.title}}
        >
          {{#if (has-block)}}
            {{yield}}
          {{else}}
            {{icon this.icon}}
          {{/if}}
        </LinkTo>
      {{/if}}
    
  */
  {
    "id": "+gduAWVD",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[32,0],[[24,0,\"c-navbar__back-button no-text btn-transparent btn\"],[16,\"title\",[30,0,[\"title\"]]]],[[\"@route\",\"@models\"],[[30,2],[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"          \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[32,1],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,0],[[24,0,\"c-navbar__back-button no-text btn-transparent btn\"],[16,\"title\",[30,0,[\"title\"]]]],[[\"@route\"],[[30,0,[\"targetRoute\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"          \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[32,1],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@routeModels\",\"@route\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/back-button.js",
    "scope": () => [_routing.LinkTo, _dIcon.default],
    "isStrictMode": true
  }), _ChatNavbarBackButton);
});