define("discourse/plugins/chat/discourse/components/chat/modal/new-message", ["exports", "@glimmer/component", "@ember/service", "discourse/components/d-modal", "discourse/plugins/chat/discourse/components/chat/message-creator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dModal, _messageCreator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatModalNewMessage;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatModalNewMessage = _exports.default = (_class = (_ChatModalNewMessage = class ChatModalNewMessage extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    }
    get shouldRender() {
      return this.siteSettings.enable_public_channels || this.chat.userCanDirectMessage;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.shouldRender}}
        <DModal
          @closeModal={{@closeModal}}
          class="chat-modal-new-message"
          @title="chat.new_message_modal.title"
          @inline={{@inline}}
          @hideHeader={{true}}
        >
          <MessageCreator @onClose={{@closeModal}} @channel={{@model}} />
        </DModal>
      {{/if}}
    
  */
  {
    "id": "m24w3NYX",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"chat-modal-new-message\"]],[[\"@closeModal\",\"@title\",\"@inline\",\"@hideHeader\"],[[30,1],\"chat.new_message_modal.title\",[30,2],true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[32,1],null,[[\"@onClose\",\"@channel\"],[[30,1],[30,3]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@closeModal\",\"@inline\",\"@model\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/new-message.js",
    "scope": () => [_dModal.default, _messageCreator.default],
    "isStrictMode": true
  }), _ChatModalNewMessage), _ChatModalNewMessage), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});