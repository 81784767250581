define("discourse/plugins/chat/discourse/components/chat/routes/channel-info", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/chat/modal/edit-channel-name", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-channel-status", "discourse/plugins/chat/discourse/components/chat/routes/channel-info-nav", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _i18n, _editChannelName, _navbar, _chatChannelStatus, _channelInfoNav, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _ChatRoutesChannelInfo;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatRoutesChannelInfo = _exports.default = (_class = (_ChatRoutesChannelInfo = class ChatRoutesChannelInfo extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatChannelInfoRouteOriginManager", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "modal", _descriptor3, this);
      _initializerDefineProperty(this, "chatGuardian", _descriptor4, this);
    }
    get canEditChannel() {
      return this.chatGuardian.canEditChatChannel() && (this.args.channel.isCategoryChannel || this.args.channel.isDirectMessageChannel && this.args.channel.chatable.group);
    }
    editChannelTitle() {
      return this.modal.show(_editChannelName.default, {
        model: this.args.channel
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-routes-channel-info">
        <Navbar as |navbar|>
          {{#if this.chatChannelInfoRouteOriginManager.isBrowse}}
            <navbar.BackButton
              @route="chat.browse"
              @title={{i18n "chat.channel_info.back_to_all_channels"}}
            />
          {{else}}
            <navbar.BackButton
              @route="chat.channel"
              @routeModels={{@channel.routeModels}}
              @title={{i18n "chat.channel_info.back_to_channel"}}
            />
          {{/if}}
          <navbar.ChannelTitle @channel={{@channel}} />
        </Navbar>
  
        <ChatChannelStatus @channel={{@channel}} />
  
        <div class="c-channel-info">
          <ChannelInfoNav @channel={{@channel}} />
          {{outlet}}
        </div>
      </div>
    
  */
  {
    "id": "zpkNa4af",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes-channel-info\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"chatChannelInfoRouteOriginManager\",\"isBrowse\"]],[[[1,\"          \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\",\"@title\"],[\"chat.browse\",[28,[32,1],[\"chat.channel_info.back_to_all_channels\"],null]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\",\"@routeModels\",\"@title\"],[\"chat.channel\",[30,2,[\"routeModels\"]],[28,[32,1],[\"chat.channel_info.back_to_channel\"],null]]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[8,[30,1,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,2],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"c-channel-info\"],[12],[1,\"\\n        \"],[8,[32,3],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n        \"],[46,[28,[31,2],null,null],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"@channel\"],false,[\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-info.js",
    "scope": () => [_navbar.default, _i18n.default, _chatChannelStatus.default, _channelInfoNav.default],
    "isStrictMode": true
  }), _ChatRoutesChannelInfo), _ChatRoutesChannelInfo), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatChannelInfoRouteOriginManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatGuardian", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "editChannelTitle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editChannelTitle"), _class.prototype)), _class);
});