define("discourse/plugins/chat/discourse/components/chat/navbar/new-direct-message-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "I18n", "discourse/plugins/chat/discourse/components/chat/modal/new-message", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _I18n, _newMessage, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _ChatNavbarNewDirectMessageButton;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatNavbarNewDirectMessageButton = _exports.default = (_class = (_ChatNavbarNewDirectMessageButton = class ChatNavbarNewDirectMessageButton extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "chat", _descriptor3, this);
      _defineProperty(this, "buttonLabel", _I18n.default.t("chat.channels_list_popup.browse"));
    }
    get showButtonComponent() {
      return this.router.currentRoute.name === "chat.direct-messages" && this.canCreateDirectMessageChannel;
    }
    get canCreateDirectMessageChannel() {
      return this.chat.userCanDirectMessage;
    }
    openNewMessageModal() {
      this.modal.show(_newMessage.default);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.showButtonComponent}}
        <DButton
          class="btn no-text btn-flat c-navbar__new-dm-button"
          title={{this.buttonLabel}}
          @action={{this.openNewMessageModal}}
          @icon="plus"
        />
      {{/if}}
    
  */
  {
    "id": "hO5vSjgE",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"showButtonComponent\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn no-text btn-flat c-navbar__new-dm-button\"],[16,\"title\",[30,0,[\"buttonLabel\"]]]],[[\"@action\",\"@icon\"],[[30,0,[\"openNewMessageModal\"]],\"plus\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/new-direct-message-button.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _ChatNavbarNewDirectMessageButton), _ChatNavbarNewDirectMessageButton), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openNewMessageModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openNewMessageModal"), _class.prototype)), _class);
});