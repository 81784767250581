define("discourse/plugins/chat/discourse/components/chat/modal/thread-settings", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax-error", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _object, _service, _template2, _dButton, _dModal, _ajaxError, _dIcon, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _ChatModalThreadSettings;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatModalThreadSettings = _exports.default = (_class = (_ChatModalThreadSettings = class ChatModalThreadSettings extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "editedTitle", _descriptor3, this);
      _initializerDefineProperty(this, "saving", _descriptor4, this);
    }
    get buttonDisabled() {
      return this.saving;
    }
    get thread() {
      return this.args.model;
    }
    get threadTitleLength() {
      return this.editedTitle.length;
    }
    saveThread() {
      this.saving = true;
      this.chatApi.editThread(this.thread.channel.id, this.thread.id, {
        title: this.editedTitle
      }).then(() => {
        this.thread.title = this.editedTitle;
        this.args.closeModal();
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.saving = false;
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @closeModal={{@closeModal}}
        class="chat-modal-thread-settings"
        @inline={{@inline}}
        @title={{i18n "chat.thread_title_modal.title"}}
      >
        <:headerPrimaryAction>
          <DButton
            @disabled={{this.buttonDisabled}}
            @action={{this.saveThread}}
            @label="chat.save"
            class="btn-transparent btn-primary"
          />
        </:headerPrimaryAction>
        <:body>
          <Input
            name="thread-title"
            class="chat-modal-thread-settings__title-input"
            maxlength="50"
            placeholder={{i18n "chat.thread_title_modal.input_placeholder"}}
            @type="text"
            @value={{this.editedTitle}}
          />
          <div class="thread-title-length">
            <span>{{this.threadTitleLength}}</span>/50
          </div>
  
          {{#if this.currentUser.admin}}
            <div class="discourse-ai-cta">
              <p class="discourse-ai-cta__title">{{icon "info-circle"}}
                {{i18n "chat.thread_title_modal.discourse_ai.title"}}</p>
              <p class="discourse-ai-cta__description">{{htmlSafe
                  (i18n
                    "chat.thread_title_modal.discourse_ai.description"
                    url="<a href='https://www.discourse.org/ai' rel='noopener noreferrer' target='_blank'>Discourse AI</a>"
                  )
                }}
              </p>
            </div>
          {{/if}}
        </:body>
        <:footer>
          <DButton
            @disabled={{this.buttonDisabled}}
            @action={{this.saveThread}}
            @label="save"
            class="btn-primary"
          />
        </:footer>
      </DModal>
    
  */
  {
    "id": "ViVOF2st",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-thread-settings\"]],[[\"@closeModal\",\"@inline\",\"@title\"],[[30,1],[30,2],[28,[32,1],[\"chat.thread_title_modal.title\"],null]]],[[\"headerPrimaryAction\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-transparent btn-primary\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[30,0,[\"saveThread\"]],\"chat.save\"]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,3,\"thread-title\"],[24,0,\"chat-modal-thread-settings__title-input\"],[24,\"maxlength\",\"50\"],[16,\"placeholder\",[28,[32,1],[\"chat.thread_title_modal.input_placeholder\"],null]]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"editedTitle\"]]]],null],[1,\"\\n        \"],[10,0],[14,0,\"thread-title-length\"],[12],[1,\"\\n          \"],[10,1],[12],[1,[30,0,[\"threadTitleLength\"]]],[13],[1,\"/50\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"          \"],[10,0],[14,0,\"discourse-ai-cta\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"discourse-ai-cta__title\"],[12],[1,[28,[32,4],[\"info-circle\"],null]],[1,\"\\n              \"],[1,[28,[32,1],[\"chat.thread_title_modal.discourse_ai.title\"],null]],[13],[1,\"\\n            \"],[10,2],[14,0,\"discourse-ai-cta__description\"],[12],[1,[28,[32,5],[[28,[32,1],[\"chat.thread_title_modal.discourse_ai.description\"],[[\"url\"],[\"<a href='https://www.discourse.org/ai' rel='noopener noreferrer' target='_blank'>Discourse AI</a>\"]]]],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-primary\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[30,0,[\"saveThread\"]],\"save\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/thread-settings.js",
    "scope": () => [_dModal.default, _i18n.default, _dButton.default, _component2.Input, _dIcon.default, _template2.htmlSafe],
    "isStrictMode": true
  }), _ChatModalThreadSettings), _ChatModalThreadSettings), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "editedTitle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.thread.title || "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveThread", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveThread"), _class.prototype)), _class);
});