define("discourse/plugins/chat/discourse/services/chat-thread-composer", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "discourse/lib/body-scroll-lock"], function (_exports, _tracking, _object, _runloop, _service, _bodyScrollLock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatThreadComposer = _exports.default = (_class = class ChatThreadComposer extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "capabilities", _descriptor2, this);
      _initializerDefineProperty(this, "appEvents", _descriptor3, this);
      _initializerDefineProperty(this, "site", _descriptor4, this);
      _initializerDefineProperty(this, "textarea", _descriptor5, this);
      _initializerDefineProperty(this, "scroller", _descriptor6, this);
    }
    init() {
      super.init(...arguments);
      this.appEvents.on("discourse:focus-changed", this, this.blur);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("discourse:focus-changed", this, this.blur);
    }
    focus() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.textarea?.focus(options);
      (0, _runloop.schedule)("afterRender", () => {
        if (this.capabilities.isIOS && !this.capabilities.isIpadOS) {
          (0, _bodyScrollLock.disableBodyScroll)(this.scroller, {
            reverse: true
          });
        }
      });
    }
    blur() {
      this.textarea?.blur();
    }
    edit(message) {
      this.chat.activeMessage = null;
      message.editing = true;
      message.thread.draft = message;
      if (this.site.desktopView) {
        this.focus({
          refreshHeight: true,
          ensureAtEnd: true
        });
      }
    }
    replyTo() {
      this.chat.activeMessage = null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "capabilities", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "textarea", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "scroller", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "focus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "blur", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "blur"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replyTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "replyTo"), _class.prototype)), _class);
});