define("discourse/plugins/chat/discourse/components/chat-notice", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/chat/discourse/components/chat/notices/mention_without_membership", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _mention_without_membership, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _ChatNotices;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const COMPONENT_DICT = {
    mention_without_membership: _mention_without_membership.default
  };
  let ChatNotices = _exports.default = (_dec = (0, _service.service)("chat-channel-notices-manager"), (_class = (_ChatNotices = class ChatNotices extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "noticesManager", _descriptor, this);
    }
    clearNotice() {
      this.noticesManager.clearNotice(this.args.notice);
    }
    get component() {
      return COMPONENT_DICT[this.args.notice.type];
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-notices__notice">
  
        {{#if @notice.textContent}}
          <p class="chat-notices__notice__content">
            {{@notice.textContent}}
          </p>
        {{else}}
          <this.component
            @channel={{@channel}}
            @notice={{@notice}}
            @clearNotice={{this.clearNotice}}
          />
        {{/if}}
  
        <DButton
          @icon="times"
          @action={{this.clearNotice}}
          class="btn-transparent chat-notices__notice__clear"
        />
      </div>
    
  */
  {
    "id": "uLTQ0Y/X",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-notices__notice\"],[12],[1,\"\\n\\n\"],[41,[30,1,[\"textContent\"]],[[[1,\"        \"],[10,2],[14,0,\"chat-notices__notice__content\"],[12],[1,\"\\n          \"],[1,[30,1,[\"textContent\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,0,[\"component\"]],null,[[\"@channel\",\"@notice\",\"@clearNotice\"],[[30,2],[30,1],[30,0,[\"clearNotice\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n      \"],[8,[32,0],[[24,0,\"btn-transparent chat-notices__notice__clear\"]],[[\"@icon\",\"@action\"],[\"times\",[30,0,[\"clearNotice\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@notice\",\"@channel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-notice.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _ChatNotices), _ChatNotices), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "noticesManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clearNotice", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearNotice"), _class.prototype)), _class));
});