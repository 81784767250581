define("discourse/plugins/chat/discourse/components/chat/routes/channel-info-nav", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "truth-helpers", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _truthHelpers, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatRoutesChannelInfoNav;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatRoutesChannelInfoNav = _exports.default = (_class = (_ChatRoutesChannelInfoNav = class ChatRoutesChannelInfoNav extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
    get showTabs() {
      return this.site.desktopView && this.args.channel.isOpen;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.showTabs}}
        <nav class="c-channel-info__nav">
          <ul class="nav nav-pills">
            <li>
              <LinkTo
                @route="chat.channel.info.settings"
                @models={{@channel.routeModels}}
                class={{if (eq @tab "settings") "active"}}
                @replace={{true}}
              >
                {{i18n "chat.channel_info.tabs.settings"}}
              </LinkTo>
            </li>
            <li>
              <LinkTo
                @route="chat.channel.info.members"
                @models={{@channel.routeModels}}
                class={{if (eq @tab "members") "active"}}
                @replace={{true}}
              >
                {{i18n "chat.channel_info.tabs.members"}}
                {{#if @channel.isCategoryChannel}}
                  <span
                    class="c-channel-info__member-count"
                  >({{@channel.membershipsCount}})</span>
                {{/if}}
              </LinkTo>
            </li>
          </ul>
        </nav>
      {{/if}}
    
  */
  {
    "id": "HQQLqc1j",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"showTabs\"]],[[[1,\"      \"],[10,\"nav\"],[14,0,\"c-channel-info__nav\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n          \"],[10,\"li\"],[12],[1,\"\\n            \"],[8,[32,0],[[16,0,[52,[28,[32,1],[[30,1],\"settings\"],null],\"active\"]]],[[\"@route\",\"@models\",\"@replace\"],[\"chat.channel.info.settings\",[30,2,[\"routeModels\"]],true]],[[\"default\"],[[[[1,\"\\n              \"],[1,[28,[32,2],[\"chat.channel_info.tabs.settings\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"li\"],[12],[1,\"\\n            \"],[8,[32,0],[[16,0,[52,[28,[32,1],[[30,1],\"members\"],null],\"active\"]]],[[\"@route\",\"@models\",\"@replace\"],[\"chat.channel.info.members\",[30,2,[\"routeModels\"]],true]],[[\"default\"],[[[[1,\"\\n              \"],[1,[28,[32,2],[\"chat.channel_info.tabs.members\"],null]],[1,\"\\n\"],[41,[30,2,[\"isCategoryChannel\"]],[[[1,\"                \"],[10,1],[14,0,\"c-channel-info__member-count\"],[12],[1,\"(\"],[1,[30,2,[\"membershipsCount\"]]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@tab\",\"@channel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-info-nav.js",
    "scope": () => [_routing.LinkTo, _truthHelpers.eq, _i18n.default],
    "isStrictMode": true
  }), _ChatRoutesChannelInfoNav), _ChatRoutesChannelInfoNav), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});