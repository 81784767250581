define("discourse/plugins/chat/discourse/components/chat/navbar/close-threads-button", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse-common/helpers/d-icon", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _dIcon, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatNavbarCloseThreadsButton;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatNavbarCloseThreadsButton = _exports.default = (_class = (_ChatNavbarCloseThreadsButton = class ChatNavbarCloseThreadsButton extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _defineProperty(this, "closeButtonTitle", _I18n.default.t("chat.thread.close"));
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.site.desktopView}}
        <LinkTo
          class="c-navbar__close-threads-button btn-transparent btn btn-icon no-text"
          @route="chat.channel"
          @models={{@channel.routeModels}}
          title={{this.closeButtonTitle}}
        >
          {{icon "times"}}
        </LinkTo>
      {{/if}}
    
  */
  {
    "id": "J2ypjGnF",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"c-navbar__close-threads-button btn-transparent btn btn-icon no-text\"],[16,\"title\",[30,0,[\"closeButtonTitle\"]]]],[[\"@route\",\"@models\"],[\"chat.channel\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"times\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/close-threads-button.js",
    "scope": () => [_routing.LinkTo, _dIcon.default],
    "isStrictMode": true
  }), _ChatNavbarCloseThreadsButton), _ChatNavbarCloseThreadsButton), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});