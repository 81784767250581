define("discourse/plugins/chat/discourse/components/chat-notices", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/chat/discourse/components/chat-notice", "discourse/plugins/chat/discourse/components/chat-retention-reminder", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _chatNotice, _chatRetentionReminder, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _ChatNotices;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatNotices = _exports.default = (_dec = (0, _service.service)("chat-channel-notices-manager"), (_class = (_ChatNotices = class ChatNotices extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "noticesManager", _descriptor, this);
    }
    get noticesForChannel() {
      return this.noticesManager.notices.filter(notice1 => notice1.channelId === this.args.channel.id);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-notices">
        <ChatRetentionReminder @channel={{@channel}} />
  
        {{#each this.noticesForChannel as |notice|}}
          <ChatNotice @notice={{notice}} @channel={{@channel}} />
        {{/each}}
      </div>
    
  */
  {
    "id": "w5+Mqhw3",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-notices\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"noticesForChannel\"]]],null]],null],null,[[[1,\"        \"],[8,[32,1],null,[[\"@notice\",\"@channel\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@channel\",\"notice\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-notices.js",
    "scope": () => [_chatRetentionReminder.default, _chatNotice.default],
    "isStrictMode": true
  }), _ChatNotices), _ChatNotices), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "noticesManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});