define("discourse/plugins/chat/discourse/components/chat/message-creator/list", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "@ember/object", "truth-helpers", "discourse/helpers/concat-class", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/message-creator/channel", "discourse/plugins/chat/discourse/components/chat/message-creator/group", "discourse/plugins/chat/discourse/components/chat/message-creator/list-action", "discourse/plugins/chat/discourse/components/chat/message-creator/user", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _object, _truthHelpers, _concatClass, _discourseI18n, _channel, _group, _listAction, _user, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _List;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let List = _exports.default = (_class = (_List = class List extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "cantAddMoreMembersLabel", _discourseI18n.default.t("chat.new_message_modal.cant_add_more_members"));
    }
    componentForItem(type1) {
      switch (type1) {
        case "list-action":
          return _listAction.default;
        case "user":
          return _user.default;
        case "group":
          return _group.default;
        case "channel":
          return _channel.default;
      }
    }
    handleEnter(item1, event1) {
      if (event1.key !== "Enter") {
        return;
      }
      if (event1.shiftKey && this.args.onShiftSelect) {
        this.args.onShiftSelect?.(item1);
      } else {
        this.args.onSelect?.(item1);
      }
    }
    handleClick(item1, event1) {
      if (event1.shiftKey && this.args.onShiftSelect) {
        this.args.onShiftSelect?.(item1);
      } else {
        this.args.onSelect?.(item1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @items}}
        <div class="chat-message-creator__list-container">
          {{#if @maxReached}}
            <div
              class="chat-message-creator__warning-max-members"
            >{{this.cantAddMoreMembersLabel}}</div>
          {{else}}
            <ul class="chat-message-creator__list">
              {{#each @items as |item|}}
                <li
                  class={{concatClass
                    "chat-message-creator__list-item"
                    (if
                      (eq item.identifier @highlightedItem.identifier)
                      "-highlighted"
                    )
                  }}
                  {{on "click" (fn this.handleClick item)}}
                  {{on "keypress" (fn this.handleEnter item)}}
                  {{on "mousemove" (fn @onHighlight item)}}
                  {{on "mouseleave" (fn @onHighlight null)}}
                  role="button"
                  tabindex="0"
                  data-identifier={{item.identifier}}
                  id={{item.id}}
                >
                  {{component
                    (this.componentForItem item.type)
                    membersCount=@membersCount
                    item=item
                  }}
                </li>
              {{/each}}
            </ul>
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "9RazZaz7",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,0],[14,0,\"chat-message-creator__list-container\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"          \"],[10,0],[14,0,\"chat-message-creator__warning-max-members\"],[12],[1,[30,0,[\"cantAddMoreMembersLabel\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,\"ul\"],[14,0,\"chat-message-creator__list\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"              \"],[11,\"li\"],[16,0,[28,[32,0],[\"chat-message-creator__list-item\",[52,[28,[32,1],[[30,3,[\"identifier\"]],[30,4,[\"identifier\"]]],null],\"-highlighted\"]],null]],[24,\"role\",\"button\"],[24,\"tabindex\",\"0\"],[16,\"data-identifier\",[30,3,[\"identifier\"]]],[16,1,[30,3,[\"id\"]]],[4,[32,2],[\"click\",[28,[32,3],[[30,0,[\"handleClick\"]],[30,3]],null]],null],[4,[32,2],[\"keypress\",[28,[32,3],[[30,0,[\"handleEnter\"]],[30,3]],null]],null],[4,[32,2],[\"mousemove\",[28,[32,3],[[30,5],[30,3]],null]],null],[4,[32,2],[\"mouseleave\",[28,[32,3],[[30,5],null],null]],null],[12],[1,\"\\n                \"],[46,[28,[30,0,[\"componentForItem\"]],[[30,3,[\"type\"]]],null],null,[[\"membersCount\",\"item\"],[[30,6],[30,3]]],null],[1,\"\\n              \"],[13],[1,\"\\n\"]],[3]],null],[1,\"          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@items\",\"@maxReached\",\"item\",\"@highlightedItem\",\"@onHighlight\",\"@membersCount\"],false,[\"if\",\"each\",\"-track-array\",\"component\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/list.js",
    "scope": () => [_concatClass.default, _truthHelpers.eq, _modifier.on, _helper.fn],
    "isStrictMode": true
  }), _List), _List), (_applyDecoratedDescriptor(_class.prototype, "handleEnter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype)), _class);
});