define("discourse/plugins/chat/discourse/services/chat-emoji-picker-manager", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "rsvp", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/config/environment", "discourse-common/lib/helpers", "discourse-common/utils/decorators"], function (_exports, _tracking, _object, _runloop, _service, _rsvp, _ajax, _ajaxError, _environment, _helpers, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const TRANSITION_TIME = (0, _environment.isTesting)() ? 0 : 125; // CSS transition time
  const DEFAULT_VISIBLE_SECTIONS = ["favorites", "smileys_&_emotion"];
  const DEFAULT_LAST_SECTION = "favorites";
  let ChatEmojiPickerManager = _exports.default = (_class = class ChatEmojiPickerManager extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      _initializerDefineProperty(this, "closing", _descriptor2, this);
      _initializerDefineProperty(this, "loading", _descriptor3, this);
      _initializerDefineProperty(this, "picker", _descriptor4, this);
      _initializerDefineProperty(this, "emojis", _descriptor5, this);
      _initializerDefineProperty(this, "visibleSections", _descriptor6, this);
      _initializerDefineProperty(this, "lastVisibleSection", _descriptor7, this);
      _initializerDefineProperty(this, "element", _descriptor8, this);
    }
    get sections() {
      return !this.loading && this.emojis ? Object.keys(this.emojis) : [];
    }
    closeExisting() {
      this.visibleSections = DEFAULT_VISIBLE_SECTIONS;
      this.lastVisibleSection = DEFAULT_LAST_SECTION;
      this.picker = null;
    }
    close() {
      this.closing = true;
      (0, _runloop.later)(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.visibleSections = DEFAULT_VISIBLE_SECTIONS;
        this.lastVisibleSection = DEFAULT_LAST_SECTION;
        this.closing = false;
        this.picker = null;
      }, TRANSITION_TIME);
    }
    addVisibleSections(sections) {
      this.visibleSections = (0, _helpers.makeArray)(this.visibleSections).concat((0, _helpers.makeArray)(sections)).uniq();
    }
    open(picker) {
      this.loadEmojis();
      if (this.picker) {
        if (this.picker.trigger === picker.trigger) {
          this.closeExisting();
        } else {
          this.closeExisting();
          this.picker = picker;
        }
      } else {
        this.picker = picker;
      }
    }
    loadEmojis() {
      if (this.emojis) {
        return _rsvp.Promise.resolve();
      }
      this.loading = true;
      return (0, _ajax.ajax)("/chat/emojis.json").then(emojis => {
        this.emojis = emojis;
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "closing", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "picker", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "emojis", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "visibleSections", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_VISIBLE_SECTIONS;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "lastVisibleSection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_LAST_SECTION;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeExisting", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "closeExisting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadEmojis", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadEmojis"), _class.prototype)), _class);
});