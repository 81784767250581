define("discourse/plugins/chat/discourse/services/chat-drafts-manager", ["exports", "@ember/runloop", "@ember/service"], function (_exports, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatDraftsManager = _exports.default = (_class = class ChatDraftsManager extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _defineProperty(this, "drafts", {});
    }
    async add(message, channelId, threadId) {
      try {
        this.drafts[this.key(channelId, threadId)] = message;
        await this.persistDraft(message, channelId, threadId);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Couldn't save draft", e);
      }
    }
    get(channelId, threadId) {
      return this.drafts[this.key(channelId, threadId)];
    }
    remove(channelId, threadId) {
      delete this.drafts[this.key(channelId, threadId)];
    }
    reset() {
      this.drafts = {};
    }
    key(channelId, threadId) {
      let key = `c-${channelId}`;
      if (threadId) {
        key += `:t-${threadId}`;
      }
      return key.toString();
    }
    async persistDraft(message, channelId, threadId) {
      try {
        await this.chatApi.saveDraft(channelId, message.toJSONDraft(), {
          threadId
        });
        message.draftSaved = true;
      } catch (e) {
        // We don't want to throw an error if the draft fails to save
      }
    }
    willDestroy() {
      (0, _runloop.cancel)(this?._persistHandler);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});