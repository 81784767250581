define("discourse/plugins/chat/discourse/components/chat-message-reaction", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "@ember/template", "ember-modifier", "truth-helpers", "discourse/helpers/concat-class", "discourse/lib/text", "discourse/plugins/chat/discourse/lib/get-reaction-text", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _service, _template2, _emberModifier, _truthHelpers, _concatClass, _text, _getReactionText, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _ChatMessageReaction;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatMessageReaction = _exports.default = (_class = (_ChatMessageReaction = class ChatMessageReaction extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "capabilities", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "tooltip", _descriptor3, this);
      _initializerDefineProperty(this, "site", _descriptor4, this);
      _initializerDefineProperty(this, "isActive", _descriptor5, this);
      _defineProperty(this, "registerTooltip", (0, _emberModifier.modifier)(element1 => {
        if (this.disableTooltip || !this.popoverContent?.length) {
          return;
        }
        const instance1 = this.tooltip.register(element1, {
          content: (0, _template2.htmlSafe)(this.popoverContent),
          identifier: "chat-message-reaction-tooltip",
          animated: false,
          placement: "top",
          fallbackPlacements: ["bottom"],
          triggers: this.site.mobileView ? ["hold"] : ["hover"]
        });
        return () => {
          instance1?.destroy();
        };
      }));
    }
    get disableTooltip() {
      return this.args.disableTooltip ?? false;
    }
    get showCount() {
      return this.args.showCount ?? true;
    }
    get emojiString() {
      return `:${this.args.reaction.emoji}:`;
    }
    get emojiUrl() {
      return (0, _text.emojiUrlFor)(this.args.reaction.emoji);
    }
    handleClick(event1) {
      event1.stopPropagation();
      this.args.onReaction?.(this.args.reaction.emoji, this.args.reaction.reacted ? "remove" : "add");
    }
    get popoverContent() {
      if (!this.args.reaction.count || !this.args.reaction.users?.length) {
        return;
      }
      return (0, _text.emojiUnescape)((0, _getReactionText.getReactionText)(this.args.reaction, this.currentUser));
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if (and @reaction this.emojiUrl)}}
        <button
          type="button"
          tabindex="0"
          class={{concatClass
            "chat-message-reaction"
            (if @reaction.reacted "reacted")
            (if this.isActive "-active")
          }}
          data-emoji-name={{@reaction.emoji}}
          title={{this.emojiString}}
          {{on "click" this.handleClick passive=true}}
          {{this.registerTooltip}}
        >
          <img
            loading="lazy"
            class="emoji"
            width="20"
            height="20"
            alt={{this.emojiString}}
            src={{this.emojiUrl}}
          />
  
          {{#if (and this.showCount @reaction.count)}}
            <span class="count">{{@reaction.count}}</span>
          {{/if}}
        </button>
      {{/if}}
    
  */
  {
    "id": "wzg8WR/+",
    "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1],[30,0,[\"emojiUrl\"]]],null],[[[1,\"      \"],[11,\"button\"],[24,\"tabindex\",\"0\"],[16,0,[28,[32,1],[\"chat-message-reaction\",[52,[30,1,[\"reacted\"]],\"reacted\"],[52,[30,0,[\"isActive\"]],\"-active\"]],null]],[16,\"data-emoji-name\",[30,1,[\"emoji\"]]],[16,\"title\",[30,0,[\"emojiString\"]]],[24,4,\"button\"],[4,[32,2],[\"click\",[30,0,[\"handleClick\"]]],[[\"passive\"],[true]]],[4,[30,0,[\"registerTooltip\"]],null,null],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"loading\",\"lazy\"],[14,0,\"emoji\"],[14,\"width\",\"20\"],[14,\"height\",\"20\"],[15,\"alt\",[30,0,[\"emojiString\"]]],[15,\"src\",[30,0,[\"emojiUrl\"]]],[12],[13],[1,\"\\n\\n\"],[41,[28,[32,0],[[30,0,[\"showCount\"]],[30,1,[\"count\"]]],null],[[[1,\"          \"],[10,1],[14,0,\"count\"],[12],[1,[30,1,[\"count\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@reaction\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-reaction.js",
    "scope": () => [_truthHelpers.and, _concatClass.default, _modifier.on],
    "isStrictMode": true
  }), _ChatMessageReaction), _ChatMessageReaction), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "capabilities", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tooltip", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "popoverContent", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "popoverContent"), _class.prototype)), _class);
});