define("discourse/plugins/chat/discourse/components/chat/notices/mention_without_membership", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/modifiers/chat/later-fn", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _service, _ajaxError, _dIcon, _i18n, _laterFn, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _MentionWithoutMembership;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MentionWithoutMembership = _exports.default = (_dec = (0, _service.service)("chat-api"), (_class = (_MentionWithoutMembership = class MentionWithoutMembership extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "invitationsSent", _descriptor2, this);
    }
    get userIds() {
      return this.args.notice.data.user_ids;
    }
    async sendInvitations(event1) {
      // preventDefault to avoid a refresh
      event1.preventDefault();
      try {
        await this.chatApi.invite(this.args.channel.id, this.userIds, {
          messageId: this.args.notice.data.messageId
        });
        this.invitationsSent = true;
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="mention-without-membership-notice">
        {{#if this.invitationsSent}}
          <span
            class="mention-without-membership-notice__invitation-sent"
            {{laterFn @clearNotice 3000}}
          >
            {{dIcon "check"}}
            <span>
              {{i18n
                "chat.mention_warning.invitations_sent"
                count=this.userIds.length
              }}
            </span>
          </span>
        {{else}}
          <p class="mention-without-membership-notice__body -without-membership">
            <span
              class="mention-without-membership-notice__body__text"
            >{{@notice.data.text}}</span>
            <a
              class="mention-without-membership-notice__body__link"
              href
              {{on "click" this.sendInvitations}}
            >
              {{i18n "chat.mention_warning.invite"}}
            </a>
          </p>
        {{/if}}
      </div>
    
  */
  {
    "id": "43SYY4H4",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"mention-without-membership-notice\"],[12],[1,\"\\n\"],[41,[30,0,[\"invitationsSent\"]],[[[1,\"        \"],[11,1],[24,0,\"mention-without-membership-notice__invitation-sent\"],[4,[32,0],[[30,1],3000],null],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"check\"],null]],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[1,[28,[32,2],[\"chat.mention_warning.invitations_sent\"],[[\"count\"],[[30,0,[\"userIds\",\"length\"]]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[14,0,\"mention-without-membership-notice__body -without-membership\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"mention-without-membership-notice__body__text\"],[12],[1,[30,2,[\"data\",\"text\"]]],[13],[1,\"\\n          \"],[11,3],[24,0,\"mention-without-membership-notice__body__link\"],[24,6,\"\"],[4,[32,3],[\"click\",[30,0,[\"sendInvitations\"]]],null],[12],[1,\"\\n            \"],[1,[28,[32,2],[\"chat.mention_warning.invite\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@clearNotice\",\"@notice\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/notices/mention_without_membership.js",
    "scope": () => [_laterFn.default, _dIcon.default, _i18n.default, _modifier.on],
    "isStrictMode": true
  }), _MentionWithoutMembership), _MentionWithoutMembership), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invitationsSent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sendInvitations", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendInvitations"), _class.prototype)), _class));
});