define("discourse/plugins/chat/discourse/initializers/chat-setup", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/get-owner", "discourse-common/lib/icon-library", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-message", "discourse/plugins/chat/discourse/lib/chat-composer-buttons", "discourse/plugins/chat/discourse/lib/hashtag-types/channel", "discourse/plugins/chat/discourse/components/chat/header/icon", "discourse/plugins/chat/discourse/components/styleguide/organisms/chat"], function (_exports, _pluginApi, _getOwner, _iconLibrary, _decorators, _discourseI18n, _chatMessage, _chatComposerButtons, _channel, _icon, _chat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let _lastForcedRefreshAt;
  const MIN_REFRESH_DURATION_MS = 180000; // 3 minutes

  (0, _iconLibrary.replaceIcon)("d-chat", "comment");
  var _default = _exports.default = (_obj = {
    name: "chat-setup",
    before: "hashtag-css-generator",
    initialize(container) {
      this.router = container.lookup("service:router");
      this.chatService = container.lookup("service:chat");
      this.chatHistory = container.lookup("service:chat-history");
      this.site = container.lookup("service:site");
      this.siteSettings = container.lookup("service:site-settings");
      this.currentUser = container.lookup("service:current-user");
      this.appEvents = container.lookup("service:app-events");
      this.appEvents.on("discourse:focus-changed", this, "_handleFocusChanged");
      if (!this.chatService.userCanChat) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.12.1", api => {
        api.onPageChange(path => {
          const route = this.router.recognize(path);
          if (route.name.startsWith("chat.")) {
            this.chatHistory.visit(route);
          }
        });
        api.registerHashtagType("channel", new _channel.default(container));
        api.registerChatComposerButton({
          id: "chat-upload-btn",
          icon: "far-image",
          label: "chat.upload",
          position: "dropdown",
          action: "uploadClicked",
          dependentKeys: ["canAttachUploads"],
          displayed() {
            return this.canAttachUploads;
          }
        });
        if (this.siteSettings.discourse_local_dates_enabled) {
          api.registerChatComposerButton({
            label: "discourse_local_dates.title",
            id: "local-dates",
            class: "chat-local-dates-btn",
            icon: "calendar-alt",
            position: "dropdown",
            action() {
              this.insertDiscourseLocalDate();
            }
          });
        }
        api.registerChatComposerButton({
          label: "chat.emoji",
          id: "emoji",
          class: "chat-emoji-btn",
          icon: "far-smile",
          position: this.site.desktopView ? "inline" : "dropdown",
          context: "channel",
          action() {
            const chatEmojiPickerManager = container.lookup("service:chat-emoji-picker-manager");
            chatEmojiPickerManager.open({
              context: "channel"
            });
          }
        });
        api.registerChatComposerButton({
          label: "chat.emoji",
          id: "channel-emoji",
          class: "chat-emoji-btn",
          icon: "discourse-emojis",
          position: "dropdown",
          context: "thread",
          action() {
            const chatEmojiPickerManager = container.lookup("service:chat-emoji-picker-manager");
            chatEmojiPickerManager.open({
              context: "thread"
            });
          }
        });
        const canSummarize = this.siteSettings.summarization_strategy && this.currentUser && this.currentUser.can_summarize;
        if (canSummarize) {
          api.registerChatComposerButton({
            translatedLabel: "chat.summarization.title",
            id: "channel-summary",
            icon: "discourse-sparkles",
            position: "dropdown",
            action: "showChannelSummaryModal"
          });
        }

        // we want to decorate the chat quote dates regardless
        // of whether the current user has chat enabled
        api.decorateCookedElement(elem => {
          const currentUser = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:current-user");
          const currentUserTimezone = currentUser?.user_option?.timezone;
          const chatTranscriptElements = elem.querySelectorAll(".chat-transcript");
          chatTranscriptElements.forEach(el => {
            const dateTimeRaw = el.dataset["datetime"];
            const dateTimeEl = el.querySelector(".chat-transcript-datetime a, .chat-transcript-datetime span");
            if (currentUserTimezone) {
              dateTimeEl.innerText = moment.tz(dateTimeRaw, currentUserTimezone).format(_discourseI18n.default.t("dates.long_no_year"));
            } else {
              dateTimeEl.innerText = moment(dateTimeRaw).format(_discourseI18n.default.t("dates.long_no_year"));
            }
            dateTimeEl.dataset.dateFormatted = true;
          });
        });
        if (!this.chatService.userCanChat) {
          return;
        }
        document.body.classList.add("chat-enabled");
        this.chatService.loadChannels();
        const chatNotificationManager = container.lookup("service:chat-notification-manager");
        chatNotificationManager.start();
        if (!this._registeredDocumentTitleCountCallback) {
          api.addDocumentTitleCounter(this.documentTitleCountCallback);
          this._registeredDocumentTitleCountCallback = true;
        }
        api.addCardClickListenerSelector(".chat-drawer-outlet");
        if (this.chatService.userCanChat) {
          api.headerIcons.add("chat", _icon.default);
        }
        api.addStyleguideSection?.({
          component: _chat.default,
          category: "organisms",
          id: "chat"
        });
        api.addChatDrawerStateCallback(_ref => {
          let {
            isDrawerActive
          } = _ref;
          if (isDrawerActive) {
            document.body.classList.add("chat-drawer-active");
          } else {
            document.body.classList.remove("chat-drawer-active");
          }
        });
        api.decorateChatMessage(function (chatMessage, chatChannel) {
          if (!this.currentUser) {
            return;
          }
          const highlightable = [`@${this.currentUser.username}`];
          if (chatChannel.allowChannelWideMentions) {
            highlightable.push(..._chatMessage.MENTION_KEYWORDS.map(k => `@${k}`));
          }
          chatMessage.querySelectorAll(".mention").forEach(node => {
            const mention = node.textContent.trim();
            if (highlightable.includes(mention)) {
              node.classList.add("highlighted", "valid-mention");
            }
          });
        });
      });
    },
    documentTitleCountCallback() {
      return this.chatService.getDocumentTitleCount();
    },
    teardown() {
      this.appEvents.off("discourse:focus-changed", this, "_handleFocusChanged");
      if (!this.chatService.userCanChat) {
        return;
      }
      _lastForcedRefreshAt = null;
      (0, _chatComposerButtons.clearChatComposerButtons)();
    },
    _handleFocusChanged(hasFocus) {
      if (!this.chatService.userCanChat) {
        return;
      }
      if (!hasFocus) {
        _lastForcedRefreshAt = Date.now();
        return;
      }
      _lastForcedRefreshAt = _lastForcedRefreshAt || Date.now();
      const duration = Date.now() - _lastForcedRefreshAt;
      if (duration <= MIN_REFRESH_DURATION_MS) {
        return;
      }
      _lastForcedRefreshAt = Date.now();
    }
  }, (_applyDecoratedDescriptor(_obj, "documentTitleCountCallback", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "documentTitleCountCallback"), _obj), _applyDecoratedDescriptor(_obj, "_handleFocusChanged", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_handleFocusChanged"), _obj)), _obj);
});