define("discourse/plugins/chat/discourse/controllers/preferences-chat", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/services/chat-audio-manager"], function (_exports, _controller, _object, _service, _ajaxError, _environment, _decorators, _discourseI18n, _chatAudioManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_NEVER = _exports.HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_ALL_NEW = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const CHAT_ATTRS = ["chat_enabled", "only_chat_push_notifications", "ignore_channel_wide_mention", "show_thread_title_prompts", "chat_sound", "chat_email_frequency", "chat_header_indicator_preference", "chat_separate_sidebar_mode"];
  const HEADER_INDICATOR_PREFERENCE_NEVER = _exports.HEADER_INDICATOR_PREFERENCE_NEVER = "never";
  const HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = "dm_and_mentions";
  const HEADER_INDICATOR_PREFERENCE_ALL_NEW = _exports.HEADER_INDICATOR_PREFERENCE_ALL_NEW = "all_new";
  const HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS = "only_mentions";
  let PreferencesChatController = _exports.default = (_class = class PreferencesChatController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatAudioManager", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _defineProperty(this, "subpageTitle", _discourseI18n.default.t("chat.admin.title"));
      _defineProperty(this, "emailFrequencyOptions", [{
        name: _discourseI18n.default.t("chat.email_frequency.never"),
        value: "never"
      }, {
        name: _discourseI18n.default.t("chat.email_frequency.when_away"),
        value: "when_away"
      }]);
      _defineProperty(this, "headerIndicatorOptions", [{
        name: _discourseI18n.default.t("chat.header_indicator_preference.all_new"),
        value: HEADER_INDICATOR_PREFERENCE_ALL_NEW
      }, {
        name: _discourseI18n.default.t("chat.header_indicator_preference.dm_and_mentions"),
        value: HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS
      }, {
        name: _discourseI18n.default.t("chat.header_indicator_preference.only_mentions"),
        value: HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS
      }, {
        name: _discourseI18n.default.t("chat.header_indicator_preference.never"),
        value: HEADER_INDICATOR_PREFERENCE_NEVER
      }]);
      _defineProperty(this, "chatSeparateSidebarModeOptions", [{
        name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.always"),
        value: "always"
      }, {
        name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.fullscreen"),
        value: "fullscreen"
      }, {
        name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.never"),
        value: "never"
      }]);
    }
    get chatSeparateSidebarMode() {
      const mode = this.model.get("user_option.chat_separate_sidebar_mode");
      if (mode === "default") {
        return this.siteSettings.chat_separate_sidebar_mode;
      } else {
        return mode;
      }
    }
    chatSounds() {
      return Object.keys(_chatAudioManager.CHAT_SOUNDS).map(value => {
        return {
          name: _discourseI18n.default.t(`chat.sounds.${value}`),
          value
        };
      });
    }
    onChangeChatSound(sound) {
      if (sound) {
        this.chatAudioManager.play(sound);
      }
      this.model.set("user_option.chat_sound", sound);
    }
    save() {
      this.set("saved", false);
      return this.model.save(CHAT_ATTRS).then(() => {
        this.set("saved", true);
        if (!(0, _environment.isTesting)()) {
          location.reload();
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatAudioManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "chatSounds", [_decorators.default], Object.getOwnPropertyDescriptor(_class.prototype, "chatSounds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeChatSound", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeChatSound"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
});