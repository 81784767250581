define("discourse/plugins/chat/discourse/modifiers/chat/on-long-press", ["exports", "@ember/destroyable", "@ember/runloop", "@ember/service", "ember-modifier", "discourse-common/lib/later", "discourse-common/utils/decorators"], function (_exports, _destroyable, _runloop, _service, _emberModifier, _later, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function cancelEvent(event) {
    event.stopPropagation();
  }
  let ChatOnLongPress = _exports.default = (_class = class ChatOnLongPress extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "capabilities", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    get enabled() {
      return this.capabilities.touch && this.site.mobileView;
    }
    modify(element, _ref) {
      let [onLongPressStart, onLongPressEnd, onLongPressCancel] = _ref;
      if (!this.enabled) {
        return;
      }
      this.element = element;
      this.onLongPressStart = onLongPressStart || (() => {});
      this.onLongPressEnd = onLongPressEnd || (() => {});
      this.onLongPressCancel = onLongPressCancel || (() => {});
      this.element.addEventListener("touchstart", this.handleTouchStart, {
        passive: true
      });
    }
    onCancel() {
      (0, _runloop.cancel)(this.timeout);
      if (this.capabilities.touch) {
        this.element.removeEventListener("touchmove", this.onCancel, {
          passive: true
        });
        this.element.removeEventListener("touchend", this.onCancel);
        this.element.removeEventListener("touchcancel", this.onCancel);
      }
      this.onLongPressCancel(this.element);
    }
    handleTouchStart(event) {
      if (event.touches.length > 1) {
        this.onCancel();
        return;
      }
      this.onLongPressStart(this.element, event);
      this.element.addEventListener("touchmove", this.onCancel, {
        passive: true
      });
      this.element.addEventListener("touchend", this.onCancel);
      this.element.addEventListener("touchcancel", this.onCancel);
      this.timeout = (0, _later.default)(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.element.addEventListener("touchend", cancelEvent, {
          once: true,
          passive: true
        });
        this.onLongPressEnd(this.element, event);
      }, 400);
    }
    cleanup() {
      if (!this.enabled) {
        return;
      }
      this.element.removeEventListener("touchstart", this.handleTouchStart, {
        passive: true
      });
      this.onCancel();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "capabilities", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onCancel", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchStart", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchStart"), _class.prototype)), _class);
});