define("discourse/plugins/chat/discourse/services/emoji-picker-scroll-observer", ["exports", "@glimmer/tracking", "@ember/service", "discourse-common/utils/decorators"], function (_exports, _tracking, _service, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let EmojiPickerScrollObserver = _exports.default = (_class = class EmojiPickerScrollObserver extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatEmojiPickerManager", _descriptor, this);
      _initializerDefineProperty(this, "enabled", _descriptor2, this);
      _defineProperty(this, "direction", "up");
      _defineProperty(this, "prevYPosition", 0);
    }
    _observerCallback(event) {
      if (!this.enabled) {
        return;
      }
      this._setScrollDirection(event.target);
      const visibleSections = [...document.querySelectorAll(".chat-emoji-picker__section")].filter(sectionElement => this._isSectionVisibleInPicker(sectionElement, event.target));
      if (visibleSections?.length) {
        let sectionElement;
        if (this.direction === "up" || this.prevYPosition < 50) {
          sectionElement = visibleSections.firstObject;
        } else {
          sectionElement = visibleSections.lastObject;
        }
        this.chatEmojiPickerManager.lastVisibleSection = sectionElement.dataset.section;
        this.chatEmojiPickerManager.addVisibleSections(visibleSections.map(s => s.dataset.section));
      }
    }
    observe(element) {
      element.addEventListener("scroll", this._observerCallback);
    }
    unobserve(element) {
      element.removeEventListener("scroll", this._observerCallback);
    }
    _setScrollDirection(target) {
      if (target.scrollTop > this.prevYPosition) {
        this.direction = "down";
      } else {
        this.direction = "up";
      }
      this.prevYPosition = target.scrollTop;
    }
    _isSectionVisibleInPicker(section, picker) {
      const {
        bottom,
        height,
        top
      } = section.getBoundingClientRect();
      const containerRect = picker.getBoundingClientRect();
      return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatEmojiPickerManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "enabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_observerCallback", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "_observerCallback"), _class.prototype)), _class);
});