define("discourse/plugins/chat/discourse/components/chat-message-in-reply-to-indicator", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "@ember/template", "discourse/helpers/replace-emoji", "discourse-common/helpers/d-icon", "discourse/plugins/chat/discourse/components/chat-emoji-avatar", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _template2, _replaceEmoji, _dIcon, _chatEmojiAvatar, _chatUserAvatar, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatMessageInReplyToIndicator;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatMessageInReplyToIndicator = _exports.default = (_class = (_ChatMessageInReplyToIndicator = class ChatMessageInReplyToIndicator extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get route() {
      if (this.hasThread) {
        return "chat.channel.thread";
      } else {
        return "chat.channel.near-message";
      }
    }
    get model() {
      if (this.hasThread) {
        return [...this.args.message.channel.routeModels, this.args.message.thread.id];
      } else {
        return [...this.args.message.channel.routeModels, this.args.message.inReplyTo.id];
      }
    }
    get hasThread() {
      return this.args.message?.channel?.threadingEnabled && this.args.message?.thread?.id;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @message.inReplyTo}}
        <LinkTo
          @route={{this.route}}
          @models={{this.model}}
          class="chat-reply is-direct-reply"
        >
          {{dIcon "share" title="chat.in_reply_to"}}
  
          {{#if @message.inReplyTo.chatWebhookEvent.emoji}}
            <ChatEmojiAvatar
              @emoji={{@message.inReplyTo.chatWebhookEvent.emoji}}
            />
          {{else}}
            <ChatUserAvatar @user={{@message.inReplyTo.user}} />
          {{/if}}
  
          <span class="chat-reply__excerpt">
            {{replaceEmoji (htmlSafe @message.inReplyTo.excerpt)}}
          </span>
        </LinkTo>
      {{/if}}
    
  */
  {
    "id": "zfH8D9bT",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"inReplyTo\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"chat-reply is-direct-reply\"]],[[\"@route\",\"@models\"],[[30,0,[\"route\"]],[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"share\"],[[\"title\"],[\"chat.in_reply_to\"]]]],[1,\"\\n\\n\"],[41,[30,1,[\"inReplyTo\",\"chatWebhookEvent\",\"emoji\"]],[[[1,\"          \"],[8,[32,2],null,[[\"@emoji\"],[[30,1,[\"inReplyTo\",\"chatWebhookEvent\",\"emoji\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,3],null,[[\"@user\"],[[30,1,[\"inReplyTo\",\"user\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n        \"],[10,1],[14,0,\"chat-reply__excerpt\"],[12],[1,\"\\n          \"],[1,[28,[32,4],[[28,[32,5],[[30,1,[\"inReplyTo\",\"excerpt\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@message\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-in-reply-to-indicator.js",
    "scope": () => [_routing.LinkTo, _dIcon.default, _chatEmojiAvatar.default, _chatUserAvatar.default, _replaceEmoji.default, _template2.htmlSafe],
    "isStrictMode": true
  }), _ChatMessageInReplyToIndicator), _ChatMessageInReplyToIndicator), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});