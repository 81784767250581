define("discourse/plugins/chat/discourse/routes/chat-channel-thread", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse"], function (_exports, _object, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatChannelThread = _exports.default = (_class = class ChatChannelThread extends _discourse.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor2, this);
      _initializerDefineProperty(this, "chat", _descriptor3, this);
      _initializerDefineProperty(this, "chatThreadPane", _descriptor4, this);
    }
    redirectToChannel(channel, transition) {
      transition.abort();
      this.chatStateManager.closeSidePanel();
      this.router.transitionTo("chat.channel", ...channel.routeModels);
    }
    model(params, transition) {
      const channel = this.modelFor("chat.channel");
      return channel.threadsManager.find(channel.id, params.threadId).catch(() => {
        this.redirectToChannel(channel, transition);
        return;
      });
    }
    afterModel(thread, transition) {
      const channel = this.modelFor("chat.channel");
      if (!channel.threadingEnabled && !thread.force) {
        this.redirectToChannel(channel, transition);
        return;
      }
      channel.activeThread = thread;
    }
    willTransition(transition) {
      if (transition.targetName === "chat.channel.index" || transition.targetName === "chat.channel.near-message" || transition.targetName === "chat.index" || !transition.targetName.startsWith("chat")) {
        this.chatStateManager.closeSidePanel();
      }
    }
    beforeModel() {
      const {
        messageId
      } = this.paramsFor(this.routeName + ".near-message");
      if (!messageId && this.controllerFor("chat-channel-thread").get("targetMessageId")) {
        this.controllerFor("chat-channel-thread").set("targetMessageId", null);
      }
      this.chatStateManager.openSidePanel();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatThreadPane", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class);
});