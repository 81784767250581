define("discourse/plugins/chat/discourse/components/chat-user-avatar", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse/helpers/concat-class", "discourse/helpers/user-avatar", "discourse/lib/url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template2, _concatClass, _userAvatar, _url, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatUserAvatar;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatUserAvatar = _exports.default = (_class = (_ChatUserAvatar = class ChatUserAvatar extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
    }
    get avatar() {
      return (0, _template2.htmlSafe)((0, _userAvatar.renderAvatar)(this.args.user, {
        imageSize: this.avatarSize
      }));
    }
    get interactive() {
      return this.args.interactive ?? true;
    }
    get avatarSize() {
      return this.args.avatarSize || "tiny";
    }
    get showPresence() {
      return this.args.showPresence ?? true;
    }
    get isOnline() {
      const users1 = (this.args.chat || this.chat).presenceChannel?.users;
      return this.showPresence && !!users1?.find(_ref => {
        let {
          id: id1,
          username: username1
        } = _ref;
        return this.args.user?.id === id1 || this.args.user?.username === username1;
      });
    }
    get userPath() {
      return (0, _url.userPath)(this.args.user.username);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class={{concatClass "chat-user-avatar" (if this.isOnline "is-online")}}
        data-username={{@user.username}}
      >
        {{#if this.interactive}}
          <a
            class="chat-user-avatar__container"
            href={{this.userPath}}
            data-user-card={{@user.username}}
          >
            {{this.avatar}}
          </a>
        {{else}}
          <span class="chat-user-avatar__container">
            {{this.avatar}}
          </span>
        {{/if}}
      </div>
    
  */
  {
    "id": "hv+lI3fX",
    "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"chat-user-avatar\",[52,[30,0,[\"isOnline\"]],\"is-online\"]],null]],[15,\"data-username\",[30,1,[\"username\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"interactive\"]],[[[1,\"        \"],[10,3],[14,0,\"chat-user-avatar__container\"],[15,6,[30,0,[\"userPath\"]]],[15,\"data-user-card\",[30,1,[\"username\"]]],[12],[1,\"\\n          \"],[1,[30,0,[\"avatar\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"chat-user-avatar__container\"],[12],[1,\"\\n          \"],[1,[30,0,[\"avatar\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@user\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-user-avatar.js",
    "scope": () => [_concatClass.default],
    "isStrictMode": true
  }), _ChatUserAvatar), _ChatUserAvatar), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});