define("discourse/plugins/chat/discourse/modifiers/chat/on-animation-end", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier", "discourse-common/utils/decorators"], function (_exports, _destroyable, _runloop, _emberModifier, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let ChatOnAnimationEnd = _exports.default = (_class = class ChatOnAnimationEnd extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [fn] = _ref;
      this.element = element;
      this.fn = fn;
      this.handler = (0, _runloop.schedule)("afterRender", () => {
        this.element.addEventListener("animationend", this.handleAnimationEnd);
      });
    }
    handleAnimationEnd() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.fn?.(this.element);
    }
    cleanup() {
      (0, _runloop.cancel)(this.handler);
      this.element?.removeEventListener("animationend", this.handleAnimationEnd);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleAnimationEnd", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleAnimationEnd"), _class.prototype)), _class);
});