define("discourse/plugins/chat/discourse/components/chat-mention-warnings", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse-common/lib/get-url", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template2, _dIcon, _i18n, _getUrl, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _ChatMentionWarnings;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatMentionWarnings = _exports.default = (_class = (_ChatMentionWarnings = class ChatMentionWarnings extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "chatComposerWarningsTracker", _descriptor3, this);
    }
    get unreachableGroupMentions() {
      return this.chatComposerWarningsTracker.unreachableGroupMentions;
    }
    get overMembersLimitGroupMentions() {
      return this.chatComposerWarningsTracker.overMembersLimitGroupMentions;
    }
    get hasTooManyMentions() {
      return this.chatComposerWarningsTracker.tooManyMentions;
    }
    get channelWideMentionDisallowed() {
      return this.chatComposerWarningsTracker.channelWideMentionDisallowed;
    }
    get mentionsCount() {
      return this.chatComposerWarningsTracker.mentionsCount;
    }
    get unreachableGroupMentionsCount() {
      return this.unreachableGroupMentions.length;
    }
    get overMembersLimitMentionsCount() {
      return this.overMembersLimitGroupMentions.length;
    }
    get hasUnreachableGroupMentions() {
      return this.unreachableGroupMentionsCount > 0;
    }
    get hasOverMembersLimitGroupMentions() {
      return this.overMembersLimitMentionsCount > 0;
    }
    get warningsCount() {
      return this.unreachableGroupMentionsCount + this.overMembersLimitMentionsCount;
    }
    get show() {
      return this.hasTooManyMentions || this.channelWideMentionDisallowed || this.hasUnreachableGroupMentions || this.hasOverMembersLimitGroupMentions;
    }
    get listStyleClass() {
      if (this.hasTooManyMentions) {
        return "chat-mention-warnings-list__simple";
      }
      if (this.warningsCount > 1) {
        return "chat-mention-warnings-list__multiple";
      } else {
        return "chat-mention-warnings-list__simple";
      }
    }
    get warningHeaderText() {
      if (this.mentionsCount <= this.warningsCount || this.hasTooManyMentions) {
        return _discourseI18n.default.t("chat.mention_warning.groups.header.all");
      } else {
        return _discourseI18n.default.t("chat.mention_warning.groups.header.some");
      }
    }
    get tooManyMentionsBody() {
      if (!this.hasTooManyMentions) {
        return;
      }
      if (this.currentUser.admin) {
        return (0, _template2.htmlSafe)(_discourseI18n.default.t("chat.mention_warning.too_many_mentions_admin", {
          count: this.siteSettings.max_mentions_per_chat_message,
          siteSettingUrl: (0, _getUrl.default)("/admin/site_settings/category/plugins?filter=max_mentions_per_chat_message")
        }));
      } else {
        return (0, _template2.htmlSafe)(_discourseI18n.default.t("chat.mention_warning.too_many_mentions", {
          count: this.siteSettings.max_mentions_per_chat_message
        }));
      }
    }
    get unreachableBody() {
      if (!this.hasUnreachableGroupMentions) {
        return;
      }
      switch (this.unreachableGroupMentionsCount) {
        case 1:
          return _discourseI18n.default.t("chat.mention_warning.groups.unreachable_1", {
            group: this.unreachableGroupMentions[0]
          });
        case 2:
          return _discourseI18n.default.t("chat.mention_warning.groups.unreachable_2", {
            group1: this.unreachableGroupMentions[0],
            group2: this.unreachableGroupMentions[1]
          });
        default:
          return _discourseI18n.default.t("chat.mention_warning.groups.unreachable_multiple", {
            group: this.unreachableGroupMentions[0],
            count: this.unreachableGroupMentionsCount - 1
          });
      }
    }
    get overMembersLimitBody() {
      if (!this.hasOverMembersLimitGroupMentions) {
        return;
      }
      return (0, _template2.htmlSafe)(_discourseI18n.default.messageFormat("chat.mention_warning.groups.too_many_members_MF", {
        groupCount: this.overMembersLimitMentionsCount,
        isAdmin: this.currentUser.admin,
        siteSettingUrl: (0, _getUrl.default)("/admin/site_settings/category/plugins?filter=max_users_notified_per_group_mention"),
        notificationLimit: this.siteSettings.max_users_notified_per_group_mention,
        group1: this.overMembersLimitGroupMentions[0],
        group2: this.overMembersLimitGroupMentions[1]
      }));
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.show}}
        <div class="chat-mention-warnings">
          <div class="chat-mention-warning__icon">
            {{dIcon "exclamation-triangle"}}
          </div>
          <div class="chat-mention-warning__text">
            <div class="chat-mention-warning__header">
              {{this.warningHeaderText}}
            </div>
            <ul class={{this.listStyleClass}}>
              {{#if this.hasTooManyMentions}}
                <li>{{this.tooManyMentionsBody}}</li>
              {{else}}
                {{#if this.channelWideMentionDisallowed}}
                  <li>{{i18n
                      "chat.mention_warning.channel_wide_mentions_disallowed"
                    }}</li>
                {{/if}}
                {{#if this.hasUnreachableGroupMentions}}
                  <li>{{this.unreachableBody}}</li>
                {{/if}}
                {{#if this.hasOverMembersLimitGroupMentions}}
                  <li>{{this.overMembersLimitBody}}</li>
                {{/if}}
              {{/if}}
            </ul>
          </div>
        </div>
      {{/if}}
    
  */
  {
    "id": "I3dNE3Vl",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-mention-warnings\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-mention-warning__icon\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"exclamation-triangle\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"chat-mention-warning__text\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-mention-warning__header\"],[12],[1,\"\\n            \"],[1,[30,0,[\"warningHeaderText\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"ul\"],[15,0,[30,0,[\"listStyleClass\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasTooManyMentions\"]],[[[1,\"              \"],[10,\"li\"],[12],[1,[30,0,[\"tooManyMentionsBody\"]]],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"channelWideMentionDisallowed\"]],[[[1,\"                \"],[10,\"li\"],[12],[1,[28,[32,1],[\"chat.mention_warning.channel_wide_mentions_disallowed\"],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasUnreachableGroupMentions\"]],[[[1,\"                \"],[10,\"li\"],[12],[1,[30,0,[\"unreachableBody\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasOverMembersLimitGroupMentions\"]],[[[1,\"                \"],[10,\"li\"],[12],[1,[30,0,[\"overMembersLimitBody\"]]],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-mention-warnings.js",
    "scope": () => [_dIcon.default, _i18n.default],
    "isStrictMode": true
  }), _ChatMentionWarnings), _ChatMentionWarnings), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatComposerWarningsTracker", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});