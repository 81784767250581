define("discourse/plugins/chat/discourse/components/chat-replying-indicator", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "@ember/utils", "discourse/helpers/concat-class", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _didUpdate, _willDestroy, _service, _utils, _concatClass, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _ChatReplyingIndicator;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatReplyingIndicator = _exports.default = (_class = (_ChatReplyingIndicator = class ChatReplyingIndicator extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "presence", _descriptor2, this);
      _initializerDefineProperty(this, "presenceChannel", _descriptor3, this);
    }
    async updateSubscription() {
      await this.unsubscribe();
      await this.subscribe();
    }
    async subscribe() {
      this.presenceChannel = this.presence.getChannel(this.args.presenceChannelName);
      await this.presenceChannel.subscribe();
    }
    async unsubscribe() {
      if (this.presenceChannel?.subscribed) {
        await this.presenceChannel.unsubscribe();
      }
    }
    get users() {
      return this.presenceChannel?.get("users")?.filter(u1 => u1.id !== this.currentUser.id) || [];
    }
    get usernames() {
      return this.users.mapBy("username");
    }
    get text() {
      if (this.usernames.length === 1) {
        return _discourseI18n.default.t("chat.replying_indicator.single_user", {
          username: this.usernames[0]
        });
      }
      if (this.usernames.length < 4) {
        const lastUsername1 = this.usernames[this.usernames.length - 1];
        const commaSeparatedUsernames1 = this.usernames.slice(0, this.usernames.length - 1).join(_discourseI18n.default.t("word_connector.comma"));
        return _discourseI18n.default.t("chat.replying_indicator.multiple_users", {
          commaSeparatedUsernames: commaSeparatedUsernames1,
          lastUsername: lastUsername1
        });
      }
      const commaSeparatedUsernames1 = this.usernames.slice(0, 2).join(_discourseI18n.default.t("word_connector.comma"));
      return _discourseI18n.default.t("chat.replying_indicator.many_users", {
        commaSeparatedUsernames: commaSeparatedUsernames1,
        count: this.usernames.length - 2
      });
    }
    get shouldRender() {
      return (0, _utils.isPresent)(this.usernames);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @presenceChannelName}}
        <div
          class={{concatClass
            "chat-replying-indicator"
            (if this.presenceChannel.subscribed "is-subscribed")
          }}
          {{didInsert this.subscribe}}
          {{didUpdate this.updateSubscription @presenceChannelName}}
          {{willDestroy this.unsubscribe}}
        >
          {{#if this.shouldRender}}
            <span class="chat-replying-indicator__text">{{this.text}}</span>
            <span class="chat-replying-indicator__wave">
              <span class="chat-replying-indicator__dot">.</span>
              <span class="chat-replying-indicator__dot">.</span>
              <span class="chat-replying-indicator__dot">.</span>
            </span>
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "mY3LAGZX",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[11,0],[16,0,[28,[32,0],[\"chat-replying-indicator\",[52,[30,0,[\"presenceChannel\",\"subscribed\"]],\"is-subscribed\"]],null]],[4,[32,1],[[30,0,[\"subscribe\"]]],null],[4,[32,2],[[30,0,[\"updateSubscription\"]],[30,1]],null],[4,[32,3],[[30,0,[\"unsubscribe\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"          \"],[10,1],[14,0,\"chat-replying-indicator__text\"],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n          \"],[10,1],[14,0,\"chat-replying-indicator__wave\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"chat-replying-indicator__dot\"],[12],[1,\".\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"chat-replying-indicator__dot\"],[12],[1,\".\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"chat-replying-indicator__dot\"],[12],[1,\".\"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@presenceChannelName\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-replying-indicator.js",
    "scope": () => [_concatClass.default, _didInsert.default, _didUpdate.default, _willDestroy.default],
    "isStrictMode": true
  }), _ChatReplyingIndicator), _ChatReplyingIndicator), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "presence", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "presenceChannel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateSubscription", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSubscription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "subscribe", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "subscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unsubscribe", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unsubscribe"), _class.prototype)), _class);
});