define("discourse/plugins/chat/discourse/models/chat-thread", ["exports", "@glimmer/tracking", "pretty-text/guid", "discourse-common/lib/get-owner", "discourse/plugins/chat/discourse/lib/chat-messages-manager", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/models/chat-thread-preview", "discourse/plugins/chat/discourse/models/chat-tracking-state", "discourse/plugins/chat/discourse/models/user-chat-thread-membership"], function (_exports, _tracking, _guid, _getOwner, _chatMessagesManager, _chatMessage, _chatThreadPreview, _chatTrackingState, _userChatThreadMembership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.THREAD_STATUSES = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const THREAD_STATUSES = _exports.THREAD_STATUSES = {
    open: "open",
    readOnly: "read_only",
    closed: "closed",
    archived: "archived"
  };
  let ChatThread = _exports.default = (_class = class ChatThread {
    static create(channel) {
      let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return new ChatThread(channel, args);
    }
    constructor(channel) {
      let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      _initializerDefineProperty(this, "id", _descriptor, this);
      _initializerDefineProperty(this, "title", _descriptor2, this);
      _initializerDefineProperty(this, "status", _descriptor3, this);
      _initializerDefineProperty(this, "draft", _descriptor4, this);
      _initializerDefineProperty(this, "staged", _descriptor5, this);
      _initializerDefineProperty(this, "channel", _descriptor6, this);
      _initializerDefineProperty(this, "originalMessage", _descriptor7, this);
      _initializerDefineProperty(this, "lastMessageId", _descriptor8, this);
      _initializerDefineProperty(this, "threadMessageBusLastId", _descriptor9, this);
      _initializerDefineProperty(this, "replyCount", _descriptor10, this);
      _initializerDefineProperty(this, "tracking", _descriptor11, this);
      _initializerDefineProperty(this, "currentUserMembership", _descriptor12, this);
      _initializerDefineProperty(this, "preview", _descriptor13, this);
      _initializerDefineProperty(this, "force", _descriptor14, this);
      _defineProperty(this, "messagesManager", new _chatMessagesManager.default((0, _getOwner.getOwnerWithFallback)(this)));
      this.id = args.id;
      this.channel = channel;
      this.status = args.status;
      this.staged = args.staged;
      this.replyCount = args.reply_count;
      this.force = args.force;
      this.originalMessage = args.original_message ? _chatMessage.default.create(channel, args.original_message) : null;
      if (this.originalMessage) {
        this.originalMessage.thread = this;
      }
      this.lastMessageId = args.last_message_id;
      this.title = args.title;
      if (args.current_user_membership) {
        this.currentUserMembership = _userChatThreadMembership.default.create(args.current_user_membership);
      }
      this.tracking = new _chatTrackingState.default((0, _getOwner.getOwnerWithFallback)(this));
      this.preview = _chatThreadPreview.default.create(args.preview);
    }
    resetDraft(user) {
      this.draft = _chatMessage.default.createDraftMessage(this.channel, {
        user,
        thread: this
      });
    }
    async stageMessage(message) {
      message.id = (0, _guid.default)();
      message.staged = true;
      message.processed = false;
      message.draft = false;
      message.createdAt = new Date();
      message.thread = this;
      this.messagesManager.addMessages([message]);
      message.manager = this.messagesManager;
    }
    get routeModels() {
      return [...this.channel.routeModels, this.id];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "status", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "draft", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "staged", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "channel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "originalMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "lastMessageId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "threadMessageBusLastId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "replyCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "tracking", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "currentUserMembership", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "preview", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "force", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});