define("discourse/plugins/chat/discourse/components/chat/header/icon", ["exports", "@glimmer/component", "@ember/service", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "discourse-common/lib/get-url", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/header/icon/unread-indicator", "discourse/plugins/chat/discourse/lib/get-user-chat-separate-sidebar-mode", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dButton, _concatClass, _dIcon, _getUrl, _discourseI18n, _unreadIndicator, _getUserChatSeparateSidebarMode, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _ChatHeaderIcon;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatHeaderIcon = _exports.default = (_class = (_ChatHeaderIcon = class ChatHeaderIcon extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
    }
    get showUnreadIndicator() {
      if (this.chatStateManager.isFullPageActive && this.site.desktopView) {
        return false;
      }
      return !this.currentUserInDnD;
    }
    get currentUserInDnD() {
      return this.args.currentUserInDnD || this.currentUser.isInDoNotDisturb();
    }
    get chatSeparateSidebarMode() {
      return (0, _getUserChatSeparateSidebarMode.getUserChatSeparateSidebarMode)(this.currentUser);
    }
    get isActive() {
      return this.args.isActive || this.chatStateManager.isFullPageActive || this.chatStateManager.isDrawerActive;
    }
    get title() {
      if (this.chatStateManager.isFullPageActive && !this.chatSeparateSidebarMode.never && this.site.desktopView) {
        return _discourseI18n.default.t("sidebar.panels.forum.label");
      }
      return _discourseI18n.default.t("chat.title_capitalized");
    }
    get icon() {
      if (this.chatStateManager.isFullPageActive && !this.chatSeparateSidebarMode.never && this.site.desktopView) {
        return "random";
      }
      return "d-chat";
    }
    get href() {
      if (this.site.mobileView && this.chatStateManager.isFullPageActive) {
        return (0, _getUrl.default)("/chat");
      }
      if (this.chatStateManager.isFullPageActive && !this.chatSeparateSidebarMode.never) {
        return (0, _getUrl.default)(this.chatStateManager.lastKnownAppURL || "/");
      }
      if (this.chatStateManager.isDrawerActive) {
        return (0, _getUrl.default)("/chat");
      }
      return (0, _getUrl.default)(this.chatStateManager.lastKnownChatURL || "/chat");
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <li class="header-dropdown-toggle chat-header-icon">
        <DButton
          @href={{this.href}}
          tabindex="0"
          class={{concatClass "icon" "btn-flat" (if this.isActive "active")}}
          title={{this.title}}
        >
          {{~icon this.icon~}}
          {{#if this.showUnreadIndicator}}
            <ChatHeaderIconUnreadIndicator
              @urgentCount={{@urgentCount}}
              @unreadCount={{@unreadCount}}
              @indicatorPreference={{@indicatorPreference}}
            />
          {{/if}}
        </DButton>
      </li>
    
  */
  {
    "id": "4ZYOm2ZW",
    "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"header-dropdown-toggle chat-header-icon\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,\"tabindex\",\"0\"],[16,0,[28,[32,1],[\"icon\",\"btn-flat\",[52,[30,0,[\"isActive\"]],\"active\"]],null]],[16,\"title\",[30,0,[\"title\"]]]],[[\"@href\"],[[30,0,[\"href\"]]]],[[\"default\"],[[[[1,[28,[32,2],[[30,0,[\"icon\"]]],null]],[41,[30,0,[\"showUnreadIndicator\"]],[[[1,\"          \"],[8,[32,3],null,[[\"@urgentCount\",\"@unreadCount\",\"@indicatorPreference\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@urgentCount\",\"@unreadCount\",\"@indicatorPreference\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/header/icon.js",
    "scope": () => [_dButton.default, _concatClass.default, _dIcon.default, _unreadIndicator.default],
    "isStrictMode": true
  }), _ChatHeaderIcon), _ChatHeaderIcon), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});