define("discourse/plugins/chat/discourse/initializers/chat-sidebar", ["exports", "@glimmer/tracking", "@ember/service", "@ember/string", "@ember/template", "discourse/components/user-status-message", "discourse/helpers/decorate-username-selector", "discourse/lib/plugin-api", "discourse/lib/text", "discourse/lib/utilities", "discourse-common/lib/avatar-utils", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/modal/new-message", "discourse/plugins/chat/discourse/lib/init-sidebar-state"], function (_exports, _tracking, _service, _string, _template, _userStatusMessage, _decorateUsernameSelector, _pluginApi, _text, _utilities, _avatarUtils, _decorators, _discourseI18n, _newMessage, _initSidebarState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var _default = _exports.default = {
    name: "chat-sidebar",
    initialize(container) {
      this.chatService = container.lookup("service:chat");
      if (!this.chatService.userCanChat) {
        return;
      }
      this.siteSettings = container.lookup("service:site-settings");
      this.currentUser = container.lookup("service:current-user");
      (0, _pluginApi.withPluginApi)("1.8.0", api => {
        const chatStateManager = container.lookup("service:chat-state-manager");
        api.addSidebarPanel(BaseCustomSidebarPanel => class ChatSidebarPanel extends BaseCustomSidebarPanel {
          constructor() {
            super(...arguments);
            _defineProperty(this, "key", _initSidebarState.CHAT_PANEL);
            _defineProperty(this, "switchButtonLabel", _discourseI18n.default.t("sidebar.panels.chat.label"));
            _defineProperty(this, "switchButtonIcon", "d-chat");
          }
          get switchButtonDefaultUrl() {
            return chatStateManager.lastKnownChatURL || "/chat";
          }
        });
        (0, _initSidebarState.initSidebarState)(api, api.getCurrentUser());
      });
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        const chatChannelsManager = container.lookup("service:chat-channels-manager");
        api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
          var _class, _descriptor;
          const SidebarChatMyThreadsSectionLink = class SidebarChatMyThreadsSectionLink extends BaseCustomSidebarSectionLink {
            constructor() {
              super(...arguments);
              _defineProperty(this, "route", "chat.threads");
              _defineProperty(this, "text", _discourseI18n.default.t("chat.my_threads.title"));
              _defineProperty(this, "title", _discourseI18n.default.t("chat.my_threads.title"));
              _defineProperty(this, "name", "user-threads");
              _defineProperty(this, "prefixType", "icon");
              _defineProperty(this, "prefixValue", "discourse-threads");
              _defineProperty(this, "suffixType", "icon");
              _defineProperty(this, "suffixCSSClass", "unread");
              if (container.isDestroyed) {
                return;
              }
            }
            get suffixValue() {
              return chatChannelsManager.publicMessageChannels.some(channel => channel.unreadThreadsCount > 0) ? "circle" : "";
            }
          };
          const SidebarChatMyThreadsSection = (_class = class _class extends BaseCustomSidebarSection {
            constructor() {
              super(...arguments);
              _initializerDefineProperty(this, "chatChannelsManager", _descriptor, this);
              // we only show `My Threads` link
              _defineProperty(this, "hideSectionHeader", true);
              _defineProperty(this, "name", "user-threads");
              // sidebar API doesn’t let you have undefined values
              // even if you don't show the section’s header
              _defineProperty(this, "title", "");
            }
            get links() {
              return [new SidebarChatMyThreadsSectionLink()];
            }
            get text() {
              return null;
            }
            get displaySection() {
              return this.chatChannelsManager.hasThreadedChannels;
            }
          }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.service], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null
          })), _class);
          return SidebarChatMyThreadsSection;
        }, _initSidebarState.CHAT_PANEL);
        if (this.siteSettings.enable_public_channels) {
          api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
            var _class2, _descriptor2, _descriptor3, _descriptor4;
            const SidebarChatChannelsSectionLink = class extends BaseCustomSidebarSectionLink {
              constructor(_ref) {
                let {
                  channel,
                  chatService
                } = _ref;
                super(...arguments);
                this.channel = channel;
                this.chatService = chatService;
              }
              get name() {
                return (0, _string.dasherize)(this.channel.slugifiedTitle);
              }
              get classNames() {
                const classes = [];
                if (this.channel.currentUserMembership.muted) {
                  classes.push("sidebar-section-link--muted");
                }
                if (this.channel.id === this.chatService.activeChannel?.id) {
                  classes.push("sidebar-section-link--active");
                }
                classes.push(`channel-${this.channel.id}`);
                return classes.join(" ");
              }
              get route() {
                return "chat.channel";
              }
              get models() {
                return this.channel.routeModels;
              }
              get text() {
                return (0, _template.htmlSafe)((0, _text.emojiUnescape)(this.channel.escapedTitle));
              }
              get prefixType() {
                return "icon";
              }
              get prefixValue() {
                return "d-chat";
              }
              get prefixColor() {
                return this.channel.chatable.color;
              }
              get title() {
                return this.channel.escapedDescription ? (0, _template.htmlSafe)(this.channel.escapedDescription) : `${this.channel.escapedTitle} ${_discourseI18n.default.t("chat.title")}`;
              }
              get prefixBadge() {
                return this.channel.chatable.read_restricted ? "lock" : "";
              }
              get suffixType() {
                return "icon";
              }
              get suffixValue() {
                return this.channel.tracking.unreadCount > 0 ||
                // We want to do this so we don't show a blue dot if the user is inside
                // the channel and a new unread thread comes in.
                this.chatService.activeChannel?.id !== this.channel.id && this.channel.unreadThreadsCountSinceLastViewed > 0 ? "circle" : "";
              }
              get suffixCSSClass() {
                return this.channel.tracking.mentionCount > 0 ? "urgent" : "unread";
              }
            };
            const SidebarChatChannelsSection = (_class2 = class _class2 extends BaseCustomSidebarSection {
              constructor() {
                super(...arguments);
                _initializerDefineProperty(this, "currentUser", _descriptor2, this);
                _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
                _initializerDefineProperty(this, "currentUserCanJoinPublicChannels", _descriptor4, this);
                if (container.isDestroyed) {
                  return;
                }
                this.chatService = container.lookup("service:chat");
                this.chatChannelsManager = container.lookup("service:chat-channels-manager");
                this.router = container.lookup("service:router");
              }
              get sectionLinks() {
                return this.chatChannelsManager.publicMessageChannels.map(channel => new SidebarChatChannelsSectionLink({
                  channel,
                  chatService: this.chatService
                }));
              }
              get name() {
                return "chat-channels";
              }
              get title() {
                return _discourseI18n.default.t("chat.chat_channels");
              }
              get text() {
                return _discourseI18n.default.t("chat.chat_channels");
              }
              get actions() {
                return [{
                  id: "browseChannels",
                  title: _discourseI18n.default.t("chat.channels_list_popup.browse"),
                  action: () => this.router.transitionTo("chat.browse.open")
                }];
              }
              get actionsIcon() {
                return "pencil-alt";
              }
              get links() {
                return this.sectionLinks;
              }
              get displaySection() {
                return this.chatStateManager.hasPreloadedChannels && (this.sectionLinks.length > 0 || this.currentUserCanJoinPublicChannels);
              }
            }, (_descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_service.service], {
              configurable: true,
              enumerable: true,
              writable: true,
              initializer: null
            }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "chatStateManager", [_service.service], {
              configurable: true,
              enumerable: true,
              writable: true,
              initializer: null
            }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "currentUserCanJoinPublicChannels", [_tracking.tracked], {
              configurable: true,
              enumerable: true,
              writable: true,
              initializer: function () {
                return this.currentUser && (this.currentUser.staff || this.currentUser.has_joinable_public_channels);
              }
            })), _class2);
            return SidebarChatChannelsSection;
          }, _initSidebarState.CHAT_PANEL);
        }
        api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
          var _class3, _class4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
          const SidebarChatDirectMessagesSectionLink = (_class3 = class _class3 extends BaseCustomSidebarSectionLink {
            constructor(_ref2) {
              let {
                channel,
                chatService,
                currentUser
              } = _ref2;
              super(...arguments);
              _defineProperty(this, "route", "chat.channel");
              _defineProperty(this, "suffixType", "icon");
              _defineProperty(this, "suffixCSSClass", "urgent");
              _defineProperty(this, "hoverType", "icon");
              _defineProperty(this, "hoverValue", "times");
              _defineProperty(this, "hoverTitle", _discourseI18n.default.t("chat.direct_messages.close"));
              this.channel = channel;
              this.chatService = chatService;
              this.currentUser = currentUser;
              if (this.oneOnOneMessage) {
                const user = this.channel.chatable.users[0];
                if (user.username !== _discourseI18n.default.t("chat.deleted_chat_username")) {
                  user.statusManager.trackStatus();
                }
              }
            }
            willDestroy() {
              if (this.oneOnOneMessage) {
                this.channel.chatable.users[0].statusManager.stopTrackingStatus();
              }
            }
            get oneOnOneMessage() {
              return this.channel.chatable.users.length === 1;
            }
            get contentComponentArgs() {
              return this.channel.chatable.users[0].get("status");
            }
            get contentComponent() {
              if (this.oneOnOneMessage) {
                return _userStatusMessage.default;
              }
            }
            get name() {
              return this.channel.slugifiedTitle;
            }
            get classNames() {
              const classes = [];
              if (this.channel.currentUserMembership.muted) {
                classes.push("sidebar-section-link--muted");
              }
              if (this.channel.id === this.chatService.activeChannel?.id) {
                classes.push("sidebar-section-link--active");
              }
              classes.push(`channel-${this.channel.id}`);
              return classes.join(" ");
            }
            get models() {
              return this.channel.routeModels;
            }
            get title() {
              if (this.channel.chatable.group) {
                return _discourseI18n.default.t("chat.placeholder_channel", {
                  channelName: this.channel.escapedTitle
                });
              } else {
                return _discourseI18n.default.t("chat.placeholder_users", {
                  commaSeparatedNames: this.channel.escapedTitle
                });
              }
            }
            get text() {
              if (this.channel.chatable.group) {
                return this.channel.title;
              } else {
                const username = this.channel.escapedTitle.replaceAll("@", "");
                return (0, _template.htmlSafe)(`${(0, _utilities.escapeExpression)(username)}${(0, _decorateUsernameSelector.decorateUsername)((0, _utilities.escapeExpression)(username))}`);
              }
            }
            get prefixType() {
              if (this.channel.chatable.group) {
                return "text";
              } else {
                return "image";
              }
            }
            get prefixValue() {
              if (this.channel.chatable.group) {
                return this.channel.membershipsCount;
              } else {
                return (0, _avatarUtils.avatarUrl)(this.channel.chatable.users[0].avatar_template, "tiny");
              }
            }
            get prefixCSSClass() {
              const activeUsers = this.chatService.presenceChannel.users;
              const user = this.channel.chatable.users[0];
              if (!!activeUsers?.findBy("id", user?.id) || !!activeUsers?.findBy("username", user?.username)) {
                return "active";
              }
              return "";
            }
            get suffixValue() {
              return this.channel.tracking.unreadCount > 0 ? "circle" : "";
            }
            get hoverAction() {
              return event => {
                event.stopPropagation();
                event.preventDefault();
                this.chatService.unfollowChannel(this.channel);
              };
            }
          }, (_applyDecoratedDescriptor(_class3.prototype, "willDestroy", [_decorators.bind], Object.getOwnPropertyDescriptor(_class3.prototype, "willDestroy"), _class3.prototype)), _class3);
          const SidebarChatDirectMessagesSection = (_class4 = class _class4 extends BaseCustomSidebarSection {
            constructor() {
              super(...arguments);
              _initializerDefineProperty(this, "site", _descriptor5, this);
              _initializerDefineProperty(this, "modal", _descriptor6, this);
              _initializerDefineProperty(this, "router", _descriptor7, this);
              _initializerDefineProperty(this, "currentUser", _descriptor8, this);
              _initializerDefineProperty(this, "chatStateManager", _descriptor9, this);
              _initializerDefineProperty(this, "userCanDirectMessage", _descriptor10, this);
              if (container.isDestroyed) {
                return;
              }
              this.chatService = container.lookup("service:chat");
              this.chatChannelsManager = container.lookup("service:chat-channels-manager");
            }
            get sectionLinks() {
              return this.chatChannelsManager.truncatedDirectMessageChannels.map(channel => new SidebarChatDirectMessagesSectionLink({
                channel,
                chatService: this.chatService,
                currentUser: this.currentUser
              }));
            }
            get name() {
              return "chat-dms";
            }
            get title() {
              return _discourseI18n.default.t("chat.direct_messages.title");
            }
            get text() {
              return _discourseI18n.default.t("chat.direct_messages.title");
            }
            get actions() {
              if (!this.userCanDirectMessage) {
                return [];
              }
              return [{
                id: "startDm",
                title: _discourseI18n.default.t("chat.direct_messages.new"),
                action: () => {
                  this.modal.show(_newMessage.default);
                }
              }];
            }
            get actionsIcon() {
              return "plus";
            }
            get links() {
              return this.sectionLinks;
            }
            get displaySection() {
              return this.chatStateManager.hasPreloadedChannels && (this.sectionLinks.length > 0 || this.userCanDirectMessage);
            }
          }, (_descriptor5 = _applyDecoratedDescriptor(_class4.prototype, "site", [_service.service], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null
          }), _descriptor6 = _applyDecoratedDescriptor(_class4.prototype, "modal", [_service.service], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null
          }), _descriptor7 = _applyDecoratedDescriptor(_class4.prototype, "router", [_service.service], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null
          }), _descriptor8 = _applyDecoratedDescriptor(_class4.prototype, "currentUser", [_service.service], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null
          }), _descriptor9 = _applyDecoratedDescriptor(_class4.prototype, "chatStateManager", [_service.service], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null
          }), _descriptor10 = _applyDecoratedDescriptor(_class4.prototype, "userCanDirectMessage", [_tracking.tracked], {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: function () {
              return this.chatService.userCanDirectMessage;
            }
          })), _class4);
          return SidebarChatDirectMessagesSection;
        }, "chat");
      });
    }
  };
});