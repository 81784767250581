define("discourse/plugins/chat/discourse/components/styleguide/chat-composer", ["exports", "@ember/component", "@glimmer/component", "@ember/application", "@ember/object", "@ember/service", "discourse/plugins/chat/discourse/lib/fabricators", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/template-factory"], function (_exports, _component, _component2, _application, _object, _service, _fabricators, _chatChannel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <StyleguideExample @title="<ChatComposer>">
    <Styleguide::Component>
      <Chat::Composer::Channel
        @channel={{this.channel}}
        @onSendMessage={{this.onSendMessage}}
      />
    </Styleguide::Component>
  
    <Styleguide::Controls>
      <Styleguide::Controls::Row @name="Disabled">
        <DToggleSwitch
          @state={{this.channel.isReadOnly}}
          {{on "click" this.toggleDisabled}}
        />
      </Styleguide::Controls::Row>
      <Styleguide::Controls::Row @name="Sending">
        <DToggleSwitch
          @state={{this.chatChannelPane.sending}}
          {{on "click" this.toggleSending}}
        />
      </Styleguide::Controls::Row>
    </Styleguide::Controls>
  </StyleguideExample>
  */
  {
    "id": "44rPT0VR",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"<ChatComposer>\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@channel\",\"@onSendMessage\"],[[30,0,[\"channel\"]],[30,0,[\"onSendMessage\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@name\"],[\"Disabled\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],[[4,[38,6],[\"click\",[30,0,[\"toggleDisabled\"]]],null]],[[\"@state\"],[[30,0,[\"channel\",\"isReadOnly\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,4],null,[[\"@name\"],[\"Sending\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],[[4,[38,6],[\"click\",[30,0,[\"toggleSending\"]]],null]],[[\"@state\"],[[30,0,[\"chatChannelPane\",\"sending\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"styleguide-example\",\"styleguide/component\",\"chat/composer/channel\",\"styleguide/controls\",\"styleguide/controls/row\",\"d-toggle-switch\",\"on\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/styleguide/chat-composer.hbs",
    "isStrictMode": false
  });
  let ChatStyleguideChatComposer = _exports.default = (_class = class ChatStyleguideChatComposer extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatChannelComposer", _descriptor, this);
      _initializerDefineProperty(this, "chatChannelPane", _descriptor2, this);
      _defineProperty(this, "channel", new _fabricators.default((0, _application.getOwner)(this)).channel({
        id: -999
      }));
    }
    toggleDisabled() {
      if (this.channel.status === _chatChannel.CHANNEL_STATUSES.open) {
        this.channel.status = _chatChannel.CHANNEL_STATUSES.readOnly;
      } else {
        this.channel.status = _chatChannel.CHANNEL_STATUSES.open;
      }
    }
    toggleSending() {
      this.chatChannelPane.sending = !this.chatChannelPane.sending;
    }
    onSendMessage() {
      this.chatChannelComposer.reset();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatChannelComposer", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatChannelPane", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleDisabled", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSending", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSending"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSendMessage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSendMessage"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatStyleguideChatComposer);
});