define("discourse/plugins/chat/discourse/components/chat/drawer-routes/settings", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat/routes/channel-info-nav", "discourse/plugins/chat/discourse/components/chat/routes/channel-info-settings", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _didInsert, _service, _discourseI18n, _navbar, _channelInfoNav, _channelInfoSettings, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _ChatDrawerRoutesSettings;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatDrawerRoutesSettings = _exports.default = (_class = (_ChatDrawerRoutesSettings = class ChatDrawerRoutesSettings extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor2, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor3, this);
    }
    get backButton() {
      return {
        route: "chat.channel",
        models: this.chat.activeChannel?.routeModels,
        title: _discourseI18n.default.t("chat.return_to_channel")
      };
    }
    async fetchChannel() {
      if (!this.args.params?.channelId) {
        return;
      }
      const channel1 = await this.chatChannelsManager.find(this.args.params.channelId);
      this.chat.activeChannel = channel1;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
        <navbar.BackButton
          @title={{this.backButton.title}}
          @route={{this.backButton.route}}
          @routeModels={{this.backButton.models}}
        />
        <navbar.ChannelTitle @channel={{this.chat.activeChannel}} />
        <navbar.Actions as |a|>
          <a.ToggleDrawerButton />
          <a.FullPageButton />
          <a.CloseDrawerButton />
        </navbar.Actions>
      </Navbar>
  
      {{#if this.chatStateManager.isDrawerExpanded}}
        <div class="chat-drawer-content" {{didInsert this.fetchChannel}}>
          {{#if this.chat.activeChannel}}
            <ChannelInfoNav
              @channel={{this.chat.activeChannel}}
              @tab="settings"
            />
            <ChannelSettings @channel={{this.chat.activeChannel}} />
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "LZgJLQdg",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"BackButton\"]],null,[[\"@title\",\"@route\",\"@routeModels\"],[[30,0,[\"backButton\",\"title\"]],[30,0,[\"backButton\",\"route\"]],[30,0,[\"backButton\",\"models\"]]]],null],[1,\"\\n      \"],[8,[30,1,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,0,[\"chat\",\"activeChannel\"]]]],null],[1,\"\\n      \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"      \"],[11,0],[24,0,\"chat-drawer-content\"],[4,[32,1],[[30,0,[\"fetchChannel\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\"]],[[[1,\"          \"],[8,[32,2],null,[[\"@channel\",\"@tab\"],[[30,0,[\"chat\",\"activeChannel\"]],\"settings\"]],null],[1,\"\\n          \"],[8,[32,3],null,[[\"@channel\"],[[30,0,[\"chat\",\"activeChannel\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"navbar\",\"a\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/settings.js",
    "scope": () => [_navbar.default, _didInsert.default, _channelInfoNav.default, _channelInfoSettings.default],
    "isStrictMode": true
  }), _ChatDrawerRoutesSettings), _ChatDrawerRoutesSettings), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchChannel"), _class.prototype)), _class);
});