define("discourse/plugins/chat/discourse/components/styleguide/chat-modal-delete-channel", ["exports", "@ember/component", "@glimmer/component", "@ember/application", "@ember/object", "@ember/service", "discourse/plugins/chat/discourse/components/chat/modal/delete-channel", "discourse/plugins/chat/discourse/lib/fabricators", "@ember/template-factory"], function (_exports, _component, _component2, _application, _object, _service, _deleteChannel, _fabricators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <StyleguideExample @title="<Chat::Modal::DeleteChannel>">
    <Styleguide::Controls::Row>
      <DButton @translatedLabel="Open modal" @action={{this.openModal}} />
    </Styleguide::Controls::Row>
  </StyleguideExample>
  */
  {
    "id": "RePA6/AH",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"<Chat::Modal::DeleteChannel>\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@translatedLabel\",\"@action\"],[\"Open modal\",[30,0,[\"openModal\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"styleguide-example\",\"styleguide/controls/row\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/styleguide/chat-modal-delete-channel.hbs",
    "isStrictMode": false
  });
  let ChatStyleguideChatModalDeleteChannel = _exports.default = (_class = class ChatStyleguideChatModalDeleteChannel extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _defineProperty(this, "channel", new _fabricators.default((0, _application.getOwner)(this)).channel());
    }
    openModal() {
      return this.modal.show(_deleteChannel.default, {
        model: {
          channel: this.channel
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatStyleguideChatModalDeleteChannel);
});