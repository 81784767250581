define("discourse/plugins/chat/discourse/components/chat-thread-title-prompt", ["exports", "@glimmer/component", "@ember/object", "@ember/runloop", "@ember/service", "I18n", "discourse/plugins/chat/discourse/components/chat/modal/thread-settings", "discourse/plugins/chat/discourse/lib/chat-constants", "discourse/plugins/chat/discourse/models/user-chat-thread-membership"], function (_exports, _component, _object, _runloop, _service, _I18n, _threadSettings, _chatConstants, _userChatThreadMembership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatThreadTitlePrompt = _exports.default = (_class = class ChatThreadTitlePrompt extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "toasts", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "site", _descriptor5, this);
      _defineProperty(this, "toastText", {
        title: _I18n.default.t("chat.thread_title_toast.title"),
        message: _I18n.default.t("chat.thread_title_toast.message"),
        dismissLabel: _I18n.default.t("chat.thread_title_toast.dismiss_action"),
        primaryLabel: _I18n.default.t("chat.thread_title_toast.primary_action")
      });
      (0, _runloop.next)(() => {
        if (this.canShowToast) {
          this.show();
          this.updateThreadTitlePrompt();
        }
      });
    }
    get membership() {
      return this.args.thread.currentUserMembership;
    }
    async updateThreadTitlePrompt() {
      try {
        const result = await this.chatApi.updateCurrentUserThreadTitlePrompt(this.args.thread.channel.id, this.args.thread.id);
        this.args.thread.currentUserMembership = _userChatThreadMembership.default.create(result.membership);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Couldn't save thread title prompt status", e);
        if (this.membership) {
          this.membership.threadTitlePromptSeen = false;
        }
      }
    }
    disableFutureThreadTitlePrompts() {
      this.currentUser.set("user_option.show_thread_title_prompts", false);
      this.currentUser.save();
    }
    get canShowToast() {
      if (this.site.desktopView || this.args.thread.originalMessage?.user?.id !== this.currentUser.id && !this.currentUser.admin) {
        return false;
      }
      const titleNotSet = this.args.thread.title === null;
      const hasReplies = this.args.thread.replyCount >= _chatConstants.THREAD_TITLE_PROMPT_THRESHOLD;
      const showPrompts = this.currentUser.user_option.show_thread_title_prompts;
      const promptNotSeen = !this.membership?.threadTitlePromptSeen;
      return titleNotSet && hasReplies && showPrompts && promptNotSeen;
    }
    show() {
      this.toasts.default({
        duration: 5000,
        showProgressBar: true,
        class: "thread-toast",
        data: {
          title: this.toastText.title,
          message: this.toastText.message,
          actions: [{
            label: this.toastText.dismissLabel,
            class: "btn-link toast-hide",
            action: toast => {
              this.disableFutureThreadTitlePrompts();
              toast.close();
            }
          }, {
            label: this.toastText.primaryLabel,
            class: "btn-primary toast-action",
            action: toast => {
              this.modal.show(_threadSettings.default, {
                model: this.args.thread
              });
              toast.close();
            }
          }]
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toasts", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateThreadTitlePrompt", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateThreadTitlePrompt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableFutureThreadTitlePrompts", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "disableFutureThreadTitlePrompts"), _class.prototype)), _class);
});