define("discourse/plugins/chat/discourse/components/chat/message-creator/user", ["exports", "@glimmer/component", "@ember/service", "ember-modifier", "truth-helpers", "discourse/components/user-status-message", "discourse/helpers/user-status", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-user-avatar", "discourse/plugins/chat/discourse/components/chat-user-display-name", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _emberModifier, _truthHelpers, _userStatusMessage, _userStatus, _discourseI18n, _chatUserAvatar, _chatUserDisplayName, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatableUser;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatableUser = _exports.default = (_class = (_ChatableUser = class ChatableUser extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _defineProperty(this, "disabledUserLabel", _discourseI18n.default.t("chat.new_message_modal.disabled_user"));
      _defineProperty(this, "trackUserStatus", (0, _emberModifier.modifier)((element1, _ref) => {
        let [user1] = _ref;
        user1.statusManager.trackStatus();
        return () => {
          user1.statusManager.stopTrackingStatus();
        };
      }));
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class="chat-message-creator__chatable -user"
        data-disabled={{not @item.enabled}}
      >
        <ChatUserAvatar @user={{@item.model}} @interactive={{false}} />
        <ChatUserDisplayName @user={{@item.model}} />
  
        {{#if (gt @item.tracking.unreadCount 0)}}
          <div class="unread-indicator -urgent"></div>
        {{/if}}
  
        {{userStatus @item.model currentUser=this.currentUser}}
  
        <div class="user-status" {{this.trackUserStatus @item.model}}>
          <UserStatusMessage
            @status={{@item.model.status}}
            @showDescription={{true}}
          />
        </div>
  
        {{#unless @item.enabled}}
          <span class="chat-message-creator__disabled-warning">
            {{this.disabledUserLabel}}
          </span>
        {{/unless}}
      </div>
    
  */
  {
    "id": "5sGUJqVt",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__chatable -user\"],[15,\"data-disabled\",[28,[32,0],[[30,1,[\"enabled\"]]],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@user\",\"@interactive\"],[[30,1,[\"model\"]],false]],null],[1,\"\\n      \"],[8,[32,2],null,[[\"@user\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\\n\"],[41,[28,[32,3],[[30,1,[\"tracking\",\"unreadCount\"]],0],null],[[[1,\"        \"],[10,0],[14,0,\"unread-indicator -urgent\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[1,[28,[32,4],[[30,1,[\"model\"]]],[[\"currentUser\"],[[30,0,[\"currentUser\"]]]]]],[1,\"\\n\\n      \"],[11,0],[24,0,\"user-status\"],[4,[30,0,[\"trackUserStatus\"]],[[30,1,[\"model\"]]],null],[12],[1,\"\\n        \"],[8,[32,5],null,[[\"@status\",\"@showDescription\"],[[30,1,[\"model\",\"status\"]],true]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[51,[30,1,[\"enabled\"]]],[[[1,\"        \"],[10,1],[14,0,\"chat-message-creator__disabled-warning\"],[12],[1,\"\\n          \"],[1,[30,0,[\"disabledUserLabel\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@item\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/user.js",
    "scope": () => [_truthHelpers.not, _chatUserAvatar.default, _chatUserDisplayName.default, _truthHelpers.gt, _userStatus.default, _userStatusMessage.default],
    "isStrictMode": true
  }), _ChatableUser), _ChatableUser), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});